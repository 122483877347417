import React, { useEffect, useMemo, useState } from "react";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import {
  BASE_URL,
  BASE_URL_IMAGE,
  CATEGORY,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import { clearAuthToken } from "../../redux/BuyTown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomHeading from "../../components/CustomHeading";
import CustomModel from "../../components/CustomModel";
import { request } from "../../services/AxiosConfig";
import {
  DialogActions,
  DialogContent,
  FormLabel,
  Typography,
} from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import CustomLoading from "../../components/CustomLoading";
import CustomButton from "../../components/CustomButton";
import CustomInputField from "../../components/CustomInputField";
import {
  CATEGORY_ID_REQUIRED_MESSAGE,
  MAX_LENGTH_FIVE_HUNDRED_MESSAGE,
  MAX_LENGTH_SIXTY_CHARS,
  NAME_REQUIRED_MESSAGE,
  ORDER_ID_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import { DeleteOutline, EditNoteOutlined } from "@mui/icons-material";

function Category() {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.buytown);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  // get all details start
  const [categoryDetails, setCategoryDetails] = useState("");

  const getCategoryDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setCategoryDetails(data);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
      setIsLoading(false);
      
    }
  };

  useEffect(() => {
    getCategoryDetails();
  }, []);

  // get all details end

  // Add code start

  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const fileTypes = ["JPG", "PNG"];
  const handleFileChange = (file) => {
    setFile(file);
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Clear preview for non-image files
      setPreviewUrl(null);
    }
  };

  const handleIconClose = () => {
    setOpen(false);
    setPreviewUrl(null);
    setFile("");
  };

  const handleAddClickOpen = () => {
    setOpen(true);
  };

  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
      setPreviewUrl(null);
      setFile("");
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("categoryId", data.categoryId);
    formData.append("orderNumber", data.orderId);
    formData.append("file", file);

    console.log("formData:", formData);
    setIsLoading(true);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({ url: CATEGORY, method: "post", data: formData, headers })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          handleAddClose(res.status);
          getCategoryDetails();

          setIsLoading(false);
        } 
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const addAction = (
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose} backgroundColor="#62710F" />
  );
  const addModel = (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            requiredErrorMessage={CATEGORY_ID_REQUIRED_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_SIXTY_CHARS}
            fieldName="categoryId"
            fieldId="categoryId"
            fieldType="text"
            fieldLabel="Category Id"
            placeholder="Enter your Category Id"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <CustomInputField
            requiredErrorMessage={NAME_REQUIRED_MESSAGE}
            maxLength={500}
            maxLengthErrorMessage={MAX_LENGTH_FIVE_HUNDRED_MESSAGE}
            fieldName="name"
            fieldId="name"
            fieldType="text"
            fieldLabel="Category Name"
            placeholder="Enter your Category Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <CustomInputField
            requiredErrorMessage={ORDER_ID_REQUIRED_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_SIXTY_CHARS}
            fieldName="orderId"
            fieldId="orderId"
            fieldType="text"
            fieldLabel="Order Number"
            placeholder="Enter your Order Number"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />

          <br />
          <br />
          <FormLabel>
            Attach your File <span style={{ color: "red" }}>*</span>
            <FileUploader
              class="py-5"
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              required
            />
            {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
            {previewUrl && (
              <img
                src={previewUrl}
                alt="Preview"
                style={{ maxWidth: "100%" }}
              />
            )}
          </FormLabel>
        </Typography>
      </DialogContent>
      <DialogActions>{addAction}</DialogActions>
    </form>
  );

  // add code end

  // delete code start
  const [cateID, setCateId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteClickOpen = (id) => {
    setCateId(id);
    console.log(id);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onSubmitDelete = async () => {
    console.log(token);
    request({
      url: `${CATEGORY}/${cateID}`,
      method: "Delete",
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getCategoryDetails();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="No"
        click={handleDeleteClose}
        backgroundColor="#ED4337"
      />
      <CustomButton
        fieldType="submit"
        buttonName="Yes"
        click={handleDeleteClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
    // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Do you want to delete this Category ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  // delete code end

  // Edit code start
  const [editOpen, setEditOpen] = useState(false);
  const [updateRes, setUpdateRes] = useState([]);
  const selectedRow = updateRes;
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [fileData, setFileData] = useState(null);
  const [editPreviewUrl, setEditPreviewUrl] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const handleNameChange = (event) => {
    setCategoryName(event.target.value);
  };
  const handleOrderNumber = (event) => {
    setOrderId(event.target.value);
  };

  const getCategoryDetailsById = async (id) => {
    try {
      setIsLoading(true);
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${CATEGORY}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;

        setUpdateRes(data.id);
        setCategoryName(data.name);
        setCategoryId(data.categoryId);
        setOrderId(data.orderNumber);

        const imageUrl = `${BASE_URL_IMAGE}/${data.image}`;
        // setFileData(data.image);
        setFileData(imageUrl);
        setEditPreviewUrl(imageUrl);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
     
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  const handleEditFileChange = (file) => {
    setFileData(file);
    setEditPreviewUrl(URL.createObjectURL(file));
  };

  const handleEditClose = (status) => {
    if (status == 200) {
      setSelectedRowId(null);
      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
    getCategoryDetails();
    setSelectedRowId(null);
    setEditPreviewUrl(null);
    setFileData("");
  };
  const handleEditClickOpen = (selectedRow) => {
    setEditOpen(true);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.id);
    setSelectedRowId(selectedRow.original.id);
    getCategoryDetailsById(selectedRow.original.id);
  };

  const onSubmitEdit = (data) => {
    if (!categoryName || categoryName.trim() === "") {
      setError("editName", {
        type: "manual",
        message: NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (categoryName.trim().length > 500) {
      setError("editName", {
        type: "manual",
        message: MAX_LENGTH_FIVE_HUNDRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (!orderId || orderId.trim() === "") {
      setError("editorderId", {
        type: "manual",
        message: ORDER_ID_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();

    formData.append("name", categoryName.trim());
    formData.append("file", fileData);
    formData.append("categoryId", categoryId);
    formData.append("orderNumber", orderId);
    const storedToken = token;

    console.log(storedToken);
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({
      url: `${BASE_URL}${CATEGORY}/${selectedRow}`,
      data: formData,
      method: "put",
      headers,
    })


      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          handleEditClose(res.status);
          getCategoryDetails();
          setEditPreviewUrl(null);
          setFileData("");
          setIsLoading(false);
        }
      })
      .catch((error) => {

        setIsLoading(false);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };
  const editAction = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
      backgroundColor="#62710F" />
  );
  const editModel = (
    <form
      onSubmit={handleSubmit(onSubmitEdit)}
      className="space-y-4 md:space-y-6 "
    >
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            fieldName="editCategoryId"
            fieldId="editCategoryId"
            fieldType="text"
            fieldLabel="Category Id"
            placeholder="Enter your Category Id"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={categoryId}
          />
          <CustomInputField
            fieldName="editName"
            fieldId="editName"
            fieldType="text"
            fieldLabel="Name"
            placeholder="Enter your Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={categoryName}
            onInput={handleNameChange}
          />
          <CustomInputField
            fieldName="editorderId"
            fieldId="editorderId"
            fieldType="text"
            fieldLabel="Order Number"
            placeholder="Enter your Order Number"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={orderId}
            onInput={handleOrderNumber}
          />

          <br />
          <br />
          <FormLabel>
            Attach your File{" "}
            <FileUploader
              class="py-5"
              handleChange={handleEditFileChange}
              name="file"
              types={fileTypes}
            //   required
            />

            {editPreviewUrl && (
              <div>
                <p>Selected File: {fileData.name}</p>
                <img
                  src={editPreviewUrl}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </div>
            )}
          </FormLabel>
        </Typography>
      </DialogContent>
      <DialogActions>{editAction}</DialogActions>
    </form>
  );

  // Edit code end

  const categoryTable = useMemo(() => [
    {
      accessorKey: "serialNo",
      header: "Serial No",
      size: 20,
      accessorFn: (row, index) => index + 1,
    },
    {
      accessorKey: "categoryId",
      header: "Category Id",
      size: 20,
    },
    {
      accessorKey: "name",
      header: "Category Name",
      size: 20,
    },
    {
      accessorKey: "orderNumber",
      header: "Order Number",
      size: 20,
    },

    {
      accessorKey: "image",
      header: "Image",
      size: 20,
      Cell: ({ row }) => (
        <img
          src={`${row.original.image}`}
          alt={row.original.image}
          style={{ width: 150, height: 150 }}
        />
      ),
    },
  ]);

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
        backgroundColor="#62710F"
      />
    </>
  );
  const rowActions = [
    {
      label: "Edit",
      icon: <EditNoteOutlined color="success" />,
      click: (row) => handleEditClickOpen(row),
    },
    {
      label: "Delete",
      icon: <DeleteOutline color="error" />,
      click: (row) => handleDeleteClickOpen(row.original.id),
    },
  ];
  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <CustomHeading title="Category" />
      <CustomModel
        title="Category"
        submit={onSubmit}
        content={addModel}
        action={addAction}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
        isLoading={isLoading}
      />

      <CustomModel
        title="Delete Category"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        isLoading={isLoading}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <CustomModel
        title="Edit Category"
        submit={onSubmitEdit}
        content={editModel}
        action={editAction}
        openStatus={editOpen}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomMaterialTable
        columns={categoryTable}
        data={categoryDetails}
        rowActions={rowActions}
        enableRowActions={true}
        actionButtons={tableActionButtons}
      />
    </div>
  );
}

export default Category;
