import {
  Close,
  ContentCopy,
  Delete,
  DeleteForever,
  FileDownload,
  ListAlt,
  Telegram,
} from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import { MaterialReactTable } from "material-react-table";
import React, { useState } from "react";

const CustomMaterialTable = (props) => {
  const actions = props.rowActions;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  return (
    <MaterialReactTable
      columns={props.columns}
      data={props.data}
      enableColumnOrdering
      enableRowActions={props.enableRowActions}
      positionActionsColumn="last
      "
      enableSorting={false}
      getRowId={props.getRowId}
      onPaginationChange={setPagination}
      state={{
        // isLoading: (props.isLoading),
        pagination,
      }}
      muiTableHeadCellProps={{
        sx: {
          fontWeight: "semi-bold",
          fontSize: 14,
          fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          fontSize: 14,
          fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
        },
      }}
      enableColumnPinning ={props.enableColumnPinning}
      enableRowPinning={props. enableRowPinning}
      rowPinningDisplayMode= 'top'
      initialState={{
       
        columnPinning: { right: ["mrt-row-actions"] },
      }}
      renderRowActions={({ row }) => {
        const renderActions = Array.isArray(actions)
          ? actions.map((i) => (
              <IconButton onClick={() => i.click(row)}>{i.icon}</IconButton>
            ))
          : actions(row).map((i) => (
              <Box>
                <IconButton onClick={() => i.click(row)}>{i.icon}</IconButton>
              </Box>
            ));

        return renderActions;
      }}
      renderTopToolbarCustomActions={() => (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              p: "0.5rem",
              flexWrap: "wrap",
            }}
          >
            {props.actionButtons}
          </Box>
        </>
      )}
    />
  );
};

export default CustomMaterialTable;
