import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CardLayout from "../../components/dashboard/CardLayout";
import RecentOrder from "../order/RecentOrder";
import { useNavigate } from "react-router";
import { BASE_URL, DASHBOARD_DETAILS } from "../../utills/ApplicationRouting";
import axios from "axios";
import { clearAuthToken } from "../../redux/BuyTown";
import CustomHeading from "../../components/CustomHeading";
import { EventAvailableOutlined, EventNoteOutlined, EventOutlined } from "@mui/icons-material";
import CustomLoading from "../../components/CustomLoading";

function Dashboard() {
  const { token, userLoginDetails } = useSelector((state) => state.buytown);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const userName = userLoginDetails ? userLoginDetails.name : "";

  // get all details start
  const [dashboardDetails, setDashboardDetails] = useState("");

  const getAllDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${DASHBOARD_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setDashboardDetails(data);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {

      setIsLoading(false);
      console.error("Error fetching data:", error.response.status);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getAllDetails();
  }, []);

  // get all details end

  const cardData = [
    {
      count: dashboardDetails.totalOrder,
      name: "Total Orders",
      icon: <EventNoteOutlined />,
      color: "red",
    },
    {
      count: dashboardDetails.pendingOrder,
      name: "Pending Orders",
      icon: <EventOutlined />,
      color: "red",
    },
    {
      count: dashboardDetails.deliveredOrder,
      name: "Delivered Orders",
      icon: <EventAvailableOutlined />,
      color: "red",
    },
  ];
  return (
    <div>
      {isLoading && <CustomLoading />}
      <h1>Welcome Back ! <b>{userName}</b></h1>
      <br />

      <CardLayout cardData={cardData} />

      <br />
      <br />
      <CustomHeading title="Recent Orders" />
      <RecentOrder />
    </div>
  );
}

export default Dashboard;
