import React, { useRef } from "react";
import { useState, useEffect } from "react";
import SideMenu from "./sidenav/SideMenu";
import logo from "../assets/image/buytown.png";
import { Link, useNavigate } from "react-router-dom";
import {
  ExitToAppOutlined,
  NotificationsOutlined,
  PersonOutline,
} from "@mui/icons-material";
import { Badge, Divider } from "@mui/material";
import { clearAuthToken } from "../redux/BuyTown";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  BASE_URL,
  NOTIFICATION,
  NOTIFICATION_READ_ALL,
} from "../utills/ApplicationRouting";

const CustomHeader = () => {
  const { token, userLoginDetails } = useSelector((state) => state.buytown);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotifyDropdownOpen, setIsNotifyDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const sidebarRef = useRef();
  const dropdownRef = useRef();
  const notifyDropDownRef = useRef();

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const toggleNotifyDropdown = () => {
    try {
      const storedToken = token;

      const response = axios.get(`${BASE_URL}${NOTIFICATION_READ_ALL}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      if (response.status === 200) {
        setNotificationCount(0);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    setIsNotifyDropdownOpen(!isNotifyDropdownOpen);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleBackdropClick = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    const handleClickOutsideSideBar = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideSideBar);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideSideBar);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutsideNotify = (event) => {
      if (
        notifyDropDownRef.current &&
        !notifyDropDownRef.current.contains(event.target)
      ) {
        setIsNotifyDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutsideNotify);

    return () => {
      document.removeEventListener("mousedown", handleClickOutsideNotify);
    };
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(clearAuthToken());
    navigate("/");
  };

  const [brandDetails, setBrandDetails] = useState([]);
  const getNotificationDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${NOTIFICATION}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      if (response.status === 200) {
        const data = response.data;
        setNotificationCount(data.length);
        setBrandDetails(data);
        setIsLoading(false);
      } else if (response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getNotificationDetails();
  }, []);

  const navigate = useNavigate();
  const yourText = userLoginDetails ? userLoginDetails.name : "";

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-100 drop-shadow-sm h-16">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                onClick={toggleSidebar}
                type="button"
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <img src={logo} className="h-11 me-3" alt="BuyTown Logo" />
            </div>
            <div className="flex items-center">
              <div className="relative mr-3" ref={notifyDropDownRef}>
                <button
                  type="button"
                  className="flex text-sm relative"
                  aria-expanded={isNotifyDropdownOpen}
                  onClick={toggleNotifyDropdown}
                >
                  <span className="sr-only">Open notifications menu</span>
                  {notificationCount > 0 ? (
                    <Badge
                      badgeContent={notificationCount}
                      color="error"
                      max={10}
                    >
                      <NotificationsOutlined />
                    </Badge>
                  ) : (
                    <NotificationsOutlined />
                  )}
                </button>

                {isNotifyDropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 rounded-md bg-white pt-3 divide-gray-100 shadow-lg max-h-60 overflow-y-auto w-64 border"
                    id="dropdown-notify"
                  >
                    <b className="pl-4 pb-3">Notifications</b>
                    <Divider style={{paddingTop:6}}/>
                   
                    <ul role="none">
                      {Array.isArray(brandDetails) &&
                        brandDetails.map((notification) => (
                          <li key={notification.id}>
                            <Link
                              to={
                                notification.page === "order"
                                  ? "/orders"
                                  : "/dashboard"
                              }
                              className="block text-left break-words px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer "
                              role="menuitem"
                              onClick={() => setIsNotifyDropdownOpen(false)}
                            >

                              <h1>

                                {notification.content}
                              </h1>
                            </Link>
                            <Divider />
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
              </div>

              <div className="relative" ref={dropdownRef}>
                <button
                  type="button"
                  className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 relative"
                  aria-expanded={isDropdownOpen}
                  onClick={toggleDropdown}
                >
                  <span className="sr-only">Open user menu</span>
                  <div className="w-10 h-10 bg-[#62710F] rounded-full flex items-center justify-center">
                    <span className="text-white font-bold text-lg">
                      {yourText ? yourText.slice(0, 1).toUpperCase() : ""}
                    </span>
                  </div>
                </button>

                {isDropdownOpen && (
                  <div
                    className="absolute right-0 mt-2 rounded-md bg-white divide-y divide-gray-100 shadow-lg w-auto"
                    id="dropdown-user"
                  >
                    <ul className="py-1" role="none">
                      <li>
                        <p
                          className="block text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                          role="menuitem"
                        >
                          <h1>
                            <b>
                              {userLoginDetails ? userLoginDetails.email : ""}
                            </b>
                          </h1>
                        </p>
                      </li>
                      <li>
                        <Link
                          to="/profile"
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          onClick={() => setIsDropdownOpen(false)}
                        >
                          <PersonOutline color="success" /> Profile
                        </Link>
                      </li>
                      <li>
                        <a
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                          role="menuitem"
                          onClick={handleLogout}
                        >
                          <ExitToAppOutlined color="error" /> Logout
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        ref={sidebarRef}
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } bg-white border-r border-gray-100 sm:translate-x-0 shadow-lg`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white">
          <SideMenu />
        </div>
      </aside>

      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-30"
          onClick={handleBackdropClick}
        />
      )}
    </>
  );
};

export default CustomHeader;
