import React from "react";
import { Button } from "@mui/material";
import { CustomStyle } from "./CustomStyle";

function CustomButton(props) {
  return (
    <Button
      variant="contained"
      type={props.fieldType}
      onClick={props.click}
      autoFocus
      disableFocusRipple={props.disableFocusRipple}
      disableRipple={props.disableRipple}
    style={{backgroundColor:props.backgroundColor}}
    >
      <span className={CustomStyle.containedButtonStyle}>
        {" "}
        {props.buttonName}{" "}
      </span>
    </Button>
  );
}

export default CustomButton;
