import React, { useEffect, useMemo, useState } from "react";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { DeleteOutline, EditNoteOutlined } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import {
  BRAND_NAME_REQUIRED_MESSAGE,
  CATEGORY_REQUIRED_MESSAGE,
  FIRST_NAME_REQUIRED_MESSAGE,
  MAX_LENGTH_TWO_FIFTY,
} from "../../utills/ApplicationConstants";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import {
  Box,
  DialogActions,
  DialogContent,
  FormLabel,
  Typography,
} from "@mui/material";
import { useForm } from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
import CustomModel from "../../components/CustomModel";

import CustomHeading from "../../components/CustomHeading";
import {
  BASE_URL,
  BASE_URL_IMAGE,
  BRANDS,
  CATEGORY,
} from "../../utills/ApplicationRouting";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import { request } from "../../services/AxiosConfig";
import { clearAuthToken } from "../../redux/BuyTown";
import CustomAutoComplete from "../../components/CustomAutoComplete";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function Brand() {
  const { token } = useSelector((state) => state.buytown);
  const fileTypes = ["JPG", "PNG"];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");

  const [fetchedImageUrl, setFetchedImageUrl] = useState(null);
  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  // get brand details start
  const [brandDetails, setBrandDetails] = useState("");
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === "" || str === null) return "";

    // Remove underscores and convert string to Pascal case
    return str
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const getBrandDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${BRANDS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setBrandDetails(data);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBrandDetails();
  }, []);

  // get brand details end

  // Add code start
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [homesData, setHomesData] = useState([]);
  const [homeId, setHomeId] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const  getCategory = async () => {
    try {
      // setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const convertedData = data.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        // const filteredData = data.filter((home) => home.name);
        setHomesData(convertedData);

        // const homeIds = filteredData.map((home) => home.categoryId);

        // homeIds.forEach((singleHomeId) => {
        //   setHomeId(singleHomeId);

        //   console.log("Individual HomeId:", singleHomeId);
        // });

        // setIsLoading(false);
      } else {
        // setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleAddClickOpen = () => {
    setOpen(true);
  };

  const handleFileChange = (file) => {
    setFile(file);
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Clear preview for non-image files
      setPreviewUrl(null);
    }
  };
  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
      setPreviewUrl(null);
      setFile("");
      setCategory(null);
    }
  };
  const handleIconClose = () => {
    setOpen(false);
    setPreviewUrl(null);
    setFile("");
  };
  const renderedAddresses = homesData.map((home, index) => ({
    address: home.name,
    value: home.categoryId,
    key: index,
  }));

  const handleHomeChange = (event, newValue) => {
    if (newValue && newValue.value !== undefined && newValue.value !== null) {
      const selectedHomeId = newValue.value;
      const selectedHome = homesData.find(
        (home) => home.categoryId === selectedHomeId
      );
      const homeOwner = selectedHome;

      setValue("selectedOption", selectedHomeId);
    } else {
      setValue("selectedOption", "");
    }
  };
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.brandName);

    formData.append("file", file);
    formData.append("categoryId", category);

    console.log("formData:", formData);
    setIsLoading(true);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    await request({
      url: BRANDS,
      method: "post",
      data: formData,
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          handleAddClose(res.status);
          setIsLoading(false);
          getBrandDetails();
          setCategory(null);
          getCategory();
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const addAction = (
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose}  backgroundColor="#62710F"/>
  );

  const addModel = (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            requiredErrorMessage={BRAND_NAME_REQUIRED_MESSAGE}
            maxLength={250}
            maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
            fieldName="brandName"
            fieldId="brandName"
            fieldType="text"
            fieldLabel="Brand Name"
            placeholder="Enter your Brand  Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />

          {/* <CustomAutoComplete
            fieldName="selectedOption"
            id="selectId"
            requiredErrorMessage={CATEGORY_REQUIRED_MESSAGE}
            options={renderedAddresses}
            fieldLabel="Select Category"
            fieldControl={control}
            setValue={setValue}
            onChange={handleHomeChange}
            fieldRequiredIcon="*"
            invalidOption="Please select valid home address"
          /> */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={homesData}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label={
                  <span>
                    Select Category <span style={{ color: "red" }}>*</span>
                  </span>
                }
              />
            )}
            onChange={(event, value) => {
              console.log(event);
              console.log(value);
              if (value !== null) {
                setCategory(value.value);
              }
            }}
          />

          <br />
          <br />
          <FormLabel>
            Attach your File <span style={{ color: "red" }}>*</span>
            <FileUploader
              class="py-5"
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              required
            />
            {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
            {previewUrl && (
              <img
                src={previewUrl}
                alt="Preview"
                style={{ maxWidth: "100%" }}
              />
            )}
          </FormLabel>
        </Typography>
      </DialogContent>
      <DialogActions>{addAction}</DialogActions>
    </form>
  );

  // Add code end

  // Delete code start
  const [brandId, setBrandId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleDeleteClickOpen = (id) => {
    setBrandId(id);
    console.log(id);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onSubmitDelete = async () => {
    console.log(token);
    request({
      url: `${BRANDS}/${brandId}`,
      method: "Delete",
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getBrandDetails();
        }
      })
      .catch((error) => {
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="No"
        click={handleDeleteClose}
        backgroundColor="#ED4337"
      />
      <CustomButton
        fieldType="submit"
        buttonName="Yes"
        click={handleDeleteClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Do you want to delete this Brand ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );
  // delete code end

  // Edit code start

  const [editBrandId, setEditBrandId] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [updateRes, setUpdateRes] = useState("");
  const [fileData, setFileData] = useState(null);
  const selectedRow = updateRes;
  const [editPreviewUrl, setEditPreviewUrl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleBrandChange = (event) => {
    setBrandName(event.target.value);
  };
  const handleEditFileChange = (file) => {
    setFileData(file);
    setEditPreviewUrl(URL.createObjectURL(file));
  };

  const handleEditClose = (status) => {
    if (status == 200) {
      setSelectedRowId(null);

      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
    getBrandDetails();
    setSelectedRowId(null);
    setEditPreviewUrl(null);
    setFileData("");
  };

  const getBrandDetailsById = async (id) => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${BRANDS}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        getCategory();
        const data = response.data;
        console.log(data.image);
        setUpdateRes(data.id);
        setBrandName(data.name);
        const imageUrl = `${BASE_URL_IMAGE}/${data.image}`;
        // setFileData(data.image);
        setFileData(imageUrl);
      setEditPreviewUrl(imageUrl);
        // setFileData(data.image);
        setFetchedImageUrl(data.image);
        setCategory(data.categoryId);
      }  else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  const handleEditClickOpen = (selectedRow) => {
    setEditOpen(true);
  
    setSelectedRowId(selectedRow.original.id);
    getBrandDetailsById(selectedRow.original.id);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.id);
    
    
  };

  const onSubmitEdit = (e) => {
    setIsLoading(true);
    if (!brandName || brandName.trim() === "") {
      setError("editBrandName", {
        type: "manual",
        message: BRAND_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (brandName) {
      if (!brandName.trim().length > 250) {
        setError("editBrandName", {
          type: "manual",
          message: MAX_LENGTH_TWO_FIFTY,
        });
        return;
      }
      setIsLoading(false);
    }

    const formData = new FormData();

    formData.append("name", brandName.trim());
    formData.append("file", fileData);
    formData.append("categoryId", category);

    const storedToken = token;

    console.log(storedToken);
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({
      url: `${BASE_URL}${BRANDS}/${selectedRow}`,
      data: formData,
      method: "put",
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleEditClose(res.status);
          getBrandDetails();
        }
      })
      .catch((error) => {
        
        setIsLoading(false);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const editAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Update "
        click={handleEditClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const getCategoryValue = (category) => {
    console.log("category :" + category);
    console.log(homesData);
    const value = homesData.filter((data) => data.value == category);
    console.log(value.length);
    if (value.length > 0) {
      console.log("success");
      return value[0].label;
    } else {
      return null;
    }
  };
  const editModel = (
    <form onSubmit={handleSubmit(onSubmitEdit)}>
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            maxLength={250}
            maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
            fieldName="editBrandName"
            fieldId="editBrandName"
            fieldType="text"
            fieldLabel="Brand Name"
            placeholder="Enter your Brand  Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={brandName}
            onInput={handleBrandChange}
          />

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={homesData}
            value={getCategoryValue(category)}
            // defaultValue={getCategoryValue(category)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label="Select Category"
              />
            )}
            onChange={(event, value) => {
              if (value !== null) {
                setCategory(value.value);
              }
            }}
          />

          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={homesData}
            sx={{ width: 300 }}
            defaultValue={homesData.filter((data) => data.value == category)[0].label}
            renderInput={(params) => <TextField {...params} label="Movie" />}
            onChange={(event,value)=>{
              setCategory(value.value);
            }}
          /> */}

          <br />
          <br />
          <FormLabel>
            Attach your File{" "}
            <FileUploader
              class="py-5"
              handleChange={handleEditFileChange}
              name="file"
              types={fileTypes}
              //   required
            />
            {editPreviewUrl && (
              <div>
                <p>Selected File: {fileData.name}</p>
                <img
                  src={editPreviewUrl}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </div>
            )}
          </FormLabel>
        </Typography>
      </DialogContent>

      <DialogActions>{editAction}</DialogActions>
    </form>
  );

  // Edit code end

  const userTable = useMemo(() => [
    {
      accessorKey: "serialNo",
      header: "Serial No",
      size: 20,
      accessorFn: (row, index) => index + 1,
    },
    {
      accessorKey: "name",
      header: "Brand Name",
      size: 20,
      accessorFn: (row) => row && row.name,
    },
    {
      accessorKey: "categoryName",
      header: "Category Name",
      size: 20,
      accessorFn: (row) =>
        convertToPascalCase(
          `${row.categoryDetails ? row.categoryDetails.name : ""}  `
        ),
    },
    {
      accessorKey: "image",
      header: "Image",
      size: 20,
      Cell: ({ row }) => (
        <img
          src={`${BASE_URL_IMAGE}/${row.original.image}`}
          alt={row.original.image}
          style={{ width: 150, height: 150 }}
        />
      ),
    },
  ]);

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
        backgroundColor="#62710F"
      />
    </>
  );
  const rowActions = [
    {
      label: "Edit",
      icon: <EditNoteOutlined color="success" />,
      click: (row) => handleEditClickOpen(row),
    },
    {
      label: "Delete",
      icon: <DeleteOutline color="error" />,
      click: (row) => handleDeleteClickOpen(row.original.id),
    },
  ];

  return (
    <div>
      <CustomToastContainer />
      <CustomHeading title="Brands" />
      {isLoading && <CustomLoading/>}
      <CustomModel
        title="Brands"
        submit={onSubmit}
        content={addModel}
        action={addAction}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
        isLoading={isLoading}
      />

      <CustomModel
        title="Edit Brand"
        submit={onSubmitEdit}
        content={editModel}
        action={editAction}
        openStatus={selectedRowId !== null}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
        isLoading={isLoading}
      />

      <CustomModel
        title="Delete Brand"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        isLoading={isLoading}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
      <CustomMaterialTable
        columns={userTable}
        data={brandDetails}
        rowActions={rowActions}
        enableRowActions={true}
        actionButtons={tableActionButtons}
      />
    </div>
  );
}

export default Brand;
