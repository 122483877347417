import { Route, Routes, useNavigate } from "react-router";
import LoginPage from "./screens/login/LoginPage";
import Dashboard from "./screens/dashboard/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setToken } from "./redux/BuyTown";
import ProductList from "./screens/products/ProductList";
import CustomLayout from "./components/CustomLayout";
import Profile from "./screens/profile/Profile";
import User from "./screens/users/User";
import Staff from "./screens/staff/Staff";
import Brand from "./screens/brand/Brand";
import Category from "./screens/category/Category";
import ChangePassword from "./screens/changepassword/ChangePassword";
import Variant from "./screens/variant/Variant";
import Orders from "./screens/order/Orders";
import ForgotPassword from "./screens/forgotpassword/ForgotPassword";

function App() {
  const { token } = useSelector((state) => state.buytown);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem("token");

    if (token === null && storedToken) {

      dispatch(setToken(storedToken));
    }

  }, [token, dispatch, navigate]);
  return (
    <>
      {token == null && (
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/forgotPassword" element={<ForgotPassword/>} />
        </Routes>
      )}
      {token != null && (
        <CustomLayout>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/users" element={<User/>} />
          <Route path="/staff" element={<Staff/>} />
          <Route path="/brands" element={<Brand/>} />
          <Route path="/category" element={<Category/>} />
          <Route path="/changepassword" element={<ChangePassword/>}/>
          <Route path="/variant" element={<Variant/>}/>
          <Route path="/orders" element={<Orders/>} />
         
        </Routes>
      </CustomLayout>
      )}
      {/* <CustomLayout>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/users" element={<User/>} />
          <Route path="/staff" element={<Staff/>} />
          <Route path="/brands" element={<Brand/>} />
        </Routes>
      </CustomLayout> */}
    </>
  );
}

export default App;
