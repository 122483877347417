import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { clearAuthToken } from "../../redux/BuyTown";
import {
  BASE_URL,
  BASE_URL_IMAGE,
  DELIVERY_PARTNER_DETAILS,
  DELIVERY_PARTNER_ORDER_DETAILS,
  ORDER,
  ORDER_UPDATE,
  OTP_UPDATE,
  PRODUCT,
  RECENT_ORDER_DETAILS,
  VARIANT,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import CustomButton from "../../components/CustomButton";
import CustomLoading from "../../components/CustomLoading";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CustomInputField from "../../components/CustomInputField";
import {
  MAX_LENGTH_FIVE_DIGITS,
  MAX_LENGTH_TWO_FIFTY,
  OTP_INVALID_MESSAGE,
  OTP_REQUIRED_MESSAGE,
  PRICE_INVALID_MESSAGE,
  PRICE_REQUIRED_MESSAGE,
  QUANTITY_INVALID_MESSAGE,
  QUANTITY_REQUIRED_MESSAGE,
  VARIANT_NAME_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import { Controller, useForm } from "react-hook-form";
import {
  FIVE_DIGITS_TWO_DECIMAL,
  ONLY_DIGITS,
} from "../../utills/ApplicationRegex";
import CustomModel from "../../components/CustomModel";
import { request } from "../../services/AxiosConfig";
import { FileUploader } from "react-drag-drop-files";
import {
  DeleteOutline,
  EditNoteOutlined,
  HowToRegOutlined,
  LocalShippingOutlined,
} from "@mui/icons-material";
import CustomToastContainer from "../../components/CustomToastContainer";
import { mkConfig, generateCsv, download } from "export-to-csv";
import { saveAs } from "file-saver";

function RecentOrder() {
  const { token, userLoginDetails } = useSelector((state) => state.buytown);
  const roles = userLoginDetails.role;
  const fileTypes = ["JPG", "PNG"];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();
  // get recentOrder details start
  const [orderDetails, setRecentOrderDetails] = useState("");
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === "" || str === null) return "";

    // Remove underscores and convert string to Pascal case
    return str
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const getRecentOrderDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${RECENT_ORDER_DETAILS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data.orderList;

        console.log(data);
        setRecentOrderDetails(data);

        setIsLoading(false);
      } else if (response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getRecentOrderDetails();
  }, []);

  // get recentOrder details end

  // Add code start

  const [orderData, setOrderData] = useState([]);

  const [deliveryPartner, setDeliveryPartner] = useState("");
  const getStaffDetails = async () => {
    try {
      // setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(
        `${BASE_URL}${DELIVERY_PARTNER_DETAILS}`,
        {
          headers: {
            Authorization: `Bearer ${storedToken}`,
          },
        }
      );
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const convertedData = data.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        setOrderData(convertedData);
      } else {
        // setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getStaffDetails();
  }, []);

  // Add code end

  // Edit code start

  const [editBrandId, setEditBrandId] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [updateRes, setUpdateRes] = useState("");
  const selectedRow = updateRes;
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleEditClose = (status) => {
    if (status == 200) {
      setSelectedRowId(null);

      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
    getRecentOrderDetails();
    setSelectedRowId(null);
  };

  const getOrderDetailsById = async (id) => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${ORDER}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        getStaffDetails();
        const data = response.data;
        console.log(data.image);
        setUpdateRes(data.id);
        setDeliveryPartner(data.userId);
      }  else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  const handleEditClickOpen = (selectedRow) => {
    setEditOpen(true);

    setSelectedRowId(selectedRow.original.id);
    getOrderDetailsById(selectedRow.original.id);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.id);
  };

  const onSubmitEdit = (e) => {
    setIsLoading(true);

    const postData = {
      deliveryPerson: deliveryPartner,
    };

    request({
      url: `${BASE_URL}${ORDER_UPDATE}/${selectedRow}`,
      data: postData,
      method: "put",
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleEditClose(res.status);
          getRecentOrderDetails();
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.res) {
          setIsLoading(false);

          console.log("error.response.status" + error.res.status);
        }
      });
  };

  const editAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Update "
        click={handleEditClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const getPartnerValue = (deliveryPartner) => {
    console.log("deliveryPartner :" + deliveryPartner);
    console.log(orderData);
    const value = orderData.filter((data) => data.value == deliveryPartner);
    console.log(value.length);
    if (value.length > 0) {
      console.log("success");
      return value[0].label;
    } else {
      return null;
    }
  };
  const editModel = (
    <form onSubmit={handleSubmit(onSubmitEdit)}>
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={orderData}
            // defaultValue={getPartnerValue(deliveryPartner)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label="Select Delivery Partner"
              />
            )}
            onChange={(event, value) => {
              if (value !== null) {
                setDeliveryPartner(value.value);
              }
            }}
          />
        </Typography>
      </DialogContent>

      <DialogActions>{editAction}</DialogActions>
    </form>
  );

  // Edit code end

  // Edit delivery code start

  const [editStatusOpen, setStatusEditOpen] = useState(false);
  const [statusUpdateRes, setStatusUpdateRes] = useState("");
  const statusSelectedRow = statusUpdateRes;
  const [statusSelectedRowId, setStatusSelectedRowId] = useState(null);
  const [userContact, setUserContact] = useState("");
  const [statusData, setStatusData] = useState("");

  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    setStatusData(selectedValue);
  };

  const handleEditStatusClose = (status) => {
    if (status == 200) {
      setStatusSelectedRowId(null);

      setStatusEditOpen(false);
    }
  };
  const handleEditStatusIconClose = () => {
    setStatusEditOpen(false);
    getRecentOrderDetails();
    setStatusSelectedRowId(null);
  };

  const getOrderStatusById = async (id) => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${ORDER}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        getStaffDetails();
        const data = response.data;
        setStatusUpdateRes(data.id);
        setStatusData(data.deliveryStatus);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  const handleDeliveryStatus = (selectedRow) => {
    setStatusEditOpen(true);

    setStatusSelectedRowId(selectedRow.original.id);
    getOrderStatusById(selectedRow.original.id);
    setUserContact(selectedRow.original.userDetails.contact);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.userDetails.contact);
  };

  const onStatusSubmitEdit = (e) => {
    setIsLoading(true);

    const postData = {
      deliveryStatus: statusData,
    };

    request({
      url: `${BASE_URL}${ORDER}/${statusSelectedRow}`,
      data: postData,
      method: "put",
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleEditStatusClose(res.status);
          if (statusData === "DELIVERED") {
            setOtpOpen(true);
          }
          getRecentOrderDetails();
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.res) {
          setIsLoading(false);

          console.log("error.response.status" + error.res.status);
        }
      });
  };

  const editStatus = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Update "
        click={handleEditStatusClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const editStatusModel = (
    <form onSubmit={handleSubmit(onStatusSubmitEdit)}>
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <Controller
            name="deliveryStatus"
            control={control}
            render={({ field, fieldState }) => (
              <FormControl
                variant="standard"
                fullWidth
                error={fieldState.invalid}
              >
                <InputLabel id="demo-simple-select-standard-label">
                  Please Select Status<span style={{ color: "red" }}> * </span>
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  // defaultValue={dueTypeData}
                  value={statusData}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleStatusChange(e);
                  }}
                  label="Please Select delivery Status"
                >
                  <MenuItem value="PENDING">Pending</MenuItem>
                  <MenuItem value="DELIVERED">Delivered</MenuItem>
                  <MenuItem value="CANCELLED">Cancel</MenuItem>
                </Select>
                <FormHelperText style={{ color: "#D32F2F" }}>
                  {fieldState.invalid ? fieldState.error?.message : ""}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Typography>
      </DialogContent>

      <DialogActions>{editStatus}</DialogActions>
    </form>
  );

  // Edit Delivery code end

  // Edit OTP start

  const [OtpOpen, setOtpOpen] = useState(false);

  const [otpData, setOtpData] = useState("");

  const handleOtpClose = (status) => {
    if (status == 200) {
      setStatusSelectedRowId(null);

      setOtpOpen(false);
    }
  };
  const handleOtpIconClose = () => {
    setOtpOpen(false);
    getRecentOrderDetails();
    setStatusSelectedRowId(null);
  };

  // const handleDeliveryStatus = (selectedRow) => {
  //   setStatusEditOpen(true);

  //   setStatusSelectedRowId(selectedRow.original.id);
  //   getOrderStatusById(selectedRow.original.id);
  //   console.log("selectedRow:", selectedRow);
  //   console.log("selectedRow.id:", selectedRow.original.id);
  // };

  const onOtpSubmit = (data) => {
    setIsLoading(true);

    const postData = {
      userName: userContact,
      otp: data.otp,
      orderId: statusSelectedRow,
    };
    console.log("postdata", postData);

    // return;

    request({
      url: `${BASE_URL}${OTP_UPDATE}`,
      data: postData,
      method: "post",
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleOtpClose(res.status);
          getRecentOrderDetails();
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.res) {
          setIsLoading(false);

          console.log("error.response.status" + error.res.status);
        }
      });
  };

  const editOtpStatus = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Update "
        click={handleOtpClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const editOtpStatusModel = (
    <form onSubmit={handleSubmit(onOtpSubmit)}>
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            requiredErrorMessage={OTP_REQUIRED_MESSAGE}
            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={OTP_INVALID_MESSAGE}
            // maxLength={4}
            // maxLengthErrorMessage={MAX_LENGTH_FIVE_DIGITS}
            fieldName="otp"
            fieldId="otp"
            fieldType="text"
            fieldLabel="OTP"
            placeholder="Enter OTP"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
        </Typography>
      </DialogContent>

      <DialogActions>{editOtpStatus}</DialogActions>
    </form>
  );

  // OTP code end
  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const formattedData = orderDetails.map((order) => {
      return {
        serialNo: orderDetails.indexOf(order) + 1,
        orderId: order.orderId || "",
        userName: order.userDetails ? order.userDetails.name : "",
        userContact: order.userDetails ? order.userDetails.contact : "",
        productDetailName: order.order
          ? order.order
            .map((orderItem) =>
              orderItem.productDetails ? orderItem.productDetails.name : ""
            )
            .join(", ")
          : "",
        quantity: order.order
          ? order.order.map((orderItem) => orderItem.quantity || "").join(", ")
          : "",
        deliveryPersonName:
          roles === "ROLE_ADMIN"
            ? order.deliveryPersonDetails
              ? order.deliveryPersonDetails.name
              : ""
            : "",
        billingAddress: roles === "ROLE_ADMIN" ? order.billingAddress : "",
        gst: roles === "ROLE_ADMIN" ? order.gst : "",
        deliveryAddress: order.deliveryAddress || "",
        deliveryStatus: convertToPascalCase(order.deliveryStatus),
        paymentType: order.paymentType || "",
        price: order.price || "",
        paymentRefId: order.paymentRefId || "",
      };
    });

    const csv = generateCsv(csvConfig)(formattedData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "order_details.csv");
  };

  const orderTable = useMemo(() => {
    const columns = [
      {
        accessorKey: "serialNo",
        header: "Serial No",
        size: 20,
        accessorFn: (row, index) => index + 1,
      },
      {
        accessorKey: "orderId",
        header: "Order Id",
        size: 20,
        accessorFn: (row) => row && row.orderId,
      },
      {
        accessorKey: "userDetails",
        header: "User Name",
        accessorFn: (row) =>
          row && row.userDetails ? row.userDetails.name : "",
      },
      {
        accessorKey: "userContact",
        header: "User Contact",
        accessorFn: (row) =>
          row && row.userDetails ? row.userDetails.contact : "",
      },
      {
        accessorKey: "productDetailName",
        header: "Product Name",
        accessorFn: (row) =>
          row && row.order
            ? row.order
              .map((orderItem) =>
                orderItem.productDetails ? orderItem.productDetails.name : ""
              )
              .join(", ")
            : "",
      },
      // {
      //   accessorKey: "mapLink",
      //   header: "User Address(Map Address)",
      //   cell: ({ row }) =>
      //     row.original.mapLink ? (
      //       <a href={row.original.mapLink} target="_blank" rel="noopener noreferrer">
      //         {row.original.mapLink}
      //       </a>
      //     ) : (
      //       ""
      //     ),
      // },

      {
        accessorKey: "mapLink",
        header: "User Address(Map Address)",
        accessorFn: ( row ) =>
          row.mapLink ? (
            <a href={row.mapLink} target="_blank" rel="noopener noreferrer">
              {row.mapLink}
            </a>
          ) : " "
          
      },
      roles === "ROLE_ADMIN"
        ? {
          accessorKey: "deliveryPersonName",
          header: "Delivery Person Name",
          accessorFn: (row) =>
            row.deliveryPersonDetails ? row.deliveryPersonDetails.name : "",
        }
        : null,
      roles === "ROLE_ADMIN"
        ? {
          accessorKey: "billingAddress",
          header: "Billing Address",
          size: 20,
          accessorFn: (row) => row.billingAddress,
        }
        : null,
      roles === "ROLE_ADMIN"
        ? {
          accessorKey: "gst",
          header: "GST",
          size: 20,
          accessorFn: (row) => row.gst,
        }
        : null,
      {
        accessorKey: "deliveryAddress",
        header: "Delivery Address",
        size: 20,
        accessorFn: (row) => row && row.deliveryAddress,
      },
      {
        accessorKey: "deliveryStatus",
        header: "Delivery Status",
        size: 20,
        accessorFn: (row) => convertToPascalCase(row && row.deliveryStatus),
      },
      {
        accessorKey: "paymentType",
        header: "Payment Type",
        accessorFn: (row) => row && row.paymentType,
      },
      {
        accessorKey: "price",
        header: "Price",
        accessorFn: (row) => row && row.price,
      },
      {
        accessorKey: "paymentRefId",
        header: "Payment Reference Id",
        accessorFn: (row) => row && row.paymentRefId,
      },
    ];

    return columns.filter((column) => column !== null);
  }, [roles]);
  const rowActions =
    roles === "ROLE_ADMIN"
      ? [
        {
          label: "Edit",
          icon: <EditNoteOutlined color="success" />,
          click: (row) => handleEditClickOpen(row),
        },
      ]
      : roles === "ROLE_PARTNER"
        ? [
          {
            label: "Edit",
            icon: <HowToRegOutlined color="success" />,
            click: (row) => handleDeliveryStatus(row),
          },
        ]
        : [];
  const tableActionButtons = (
    <>
      {orderDetails.length > 0 ? (
        <CustomButton
          fieldType="button"
          buttonName="Export Data"
          click={handleExportData}
          backgroundColor="#62710F"
        />
      ) : null}
    </>
  );
  return (
    <div>
      <CustomToastContainer />
      <CustomMaterialTable
        columns={orderTable}
        data={orderDetails}
        enableColumnPinning={true}
        enableRowActions={true}
        rowActions={rowActions}
        actionButtons={tableActionButtons}
        isLoading={isLoading}
      />
      <CustomModel
        title="Edit"
        submit={onSubmitEdit}
        content={editModel}
        action={editAction}
        openStatus={selectedRowId !== null}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomModel
        title="Change Delivery Status"
        submit={onStatusSubmitEdit}
        content={editStatusModel}
        action={editStatus}
        openStatus={statusSelectedRowId !== null}
        closeStatus={handleEditStatusClose}
        iconCloseStatus={handleEditStatusIconClose}
        reset={reset}
        isLoading={isLoading}
      />

      <CustomModel
        title="OTP"
        submit={onOtpSubmit}
        content={editOtpStatusModel}
        action={editOtpStatus}
        openStatus={OtpOpen}
        closeStatus={handleOtpClose}
        iconCloseStatus={handleOtpIconClose}
        reset={reset}
        isLoading={isLoading}
      />
    </div>
  );
}

export default RecentOrder;
