import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../CustomInputField";
import CustomButton from "../CustomButton";
import {
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  PASSWORD_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { EMAIL_PATTERN } from "../../utills/ApplicationRegex";
import { useNavigate } from "react-router";
import { ADMIN_LOGIN, BASE_URL } from "../../utills/ApplicationRouting";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setToken, setUserLoginDetails } from "../../redux/BuyTown";
import CustomToastContainer from "../CustomToastContainer";
import CustomLoading from "../CustomLoading";
import { Link } from "react-router-dom";
import logo from "../../assets/image/buytown.png";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData = {
      userName: data.email,
      password: data.password,
      role: "ROLE_PARTNER"
    };

    axios
      .post(`${BASE_URL}${ADMIN_LOGIN}`, postData)
      .then((response) => {
        console.log(response.data.data);
        setIsLoading(true);

        if (response.status === 200) {
          console.log(response);
          console.log("response.data.user", response.data.user.roles);
          localStorage.setItem("token", response.data.token);

          dispatch(setToken(response.data.token));

          dispatch(setUserLoginDetails(response.data.user));
          setIsLoading(false);
          navigate("/");
        }
      })
      .catch((error) => {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 3000);
        if (error.response && error.response.status !== 200) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
      });
  };

  return (
    <>
      <CustomToastContainer />
      <div className="flex items-center justify-center min-h-screen ">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
          <div className="flex flex-col items-center justify-center mb-6">
            <img className="w-44 h-14" src={logo} alt="BuyTown" />
          </div>
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl text-center mb-6">
            Login
          </h1>
          <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <CustomInputField
                fieldName="email"
                fieldType="text"
                fieldLabel="Email"
                placeholder="Enter your email"
                fieldControl={control}
                fieldError={errors}
                requiredIcon="*"
                requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
                fieldPattern={EMAIL_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
              />
            </div>
            <div className="relative z-0 w-full mb-6 group">
              <Controller
                name="password"
                control={control}
                rules={{ required: PASSWORD_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    placeholder="Enter your password"
                    label={
                      <span>
                        Password <span style={{ color: "red" }}>*</span>
                      </span>
                    }
                    fullWidth
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-center">
              <CustomButton
                fieldType="submit"
                buttonName="Login"
                backgroundColor="#62710F"
                disableFocusRipple={true}
              />
              {isLoading && <CustomLoading />}
            </div>

            <hr />
            <div className="flex items-center justify-center">
              <Link
                to='/forgotPassword'
                className="text-sm font-medium text-primary-600 hover:underline"
              >
                Forgot password?
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
