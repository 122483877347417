
import '../../index.css'



function CardLayout(props) {
  const maxWidth = 810;
  const data=props.cardData;
  return (
    <section class="grid md:grid-cols-2 xl:grid-cols-3 gap-6">
       {data.map(i=>{
        return(
      
          <div className="draggable-container">
            <div class="flex items-center p-8 bg-white  rounded-lg  shadow-lg ">
              <div class={`inline-flex flex-shrink-0 items-center justify-center h-16 w-16 text-white bg-[#62710F] rounded-full mr-6`}>
               {i.icon}
              </div>
              <div>
                <span class="block text-2xl font-bold">{i.count}</span>
                <span class="block text-gray-500 text-xs mt-1">
                 {i.name}
                </span>
              </div>

              {/* <Link to='#' class="text-gray-500 text-xs text-right mt-3 underline">+More Details</Link> */}
            </div>
          </div>
    
        );
       })}
     
        

       
      </section>
  )
}

export default CardLayout