import React from "react";
import SideMenuItem from "./SideMenuItem";
import SideMenuDropdown from "./SideMenuDropdown";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import {
  PersonAddAltOutlined,
  PeopleAltOutlined,
  ListAltOutlined,
  CardGiftcardOutlined,
  ShoppingCartCheckoutOutlined,
  DynamicFeedOutlined,
  FormatBoldOutlined,
  PostAddOutlined,
} from "@mui/icons-material";
import "./SideNavStyle.css";
import { useSelector } from "react-redux";

const SideMenu = () => {
  const { token, userLoginDetails } = useSelector((state) => state.buytown);
  const roles = userLoginDetails.role;
  const menuItemdata = [
    {
      navLink: "/",
      name: "Dashboard",
      icon: <DashboardOutlinedIcon />,
    },
  
      ...(roles == "ROLE_ADMIN"
      ? [
          {
            navLink: "/category",
            name: "Category",
            icon: <DynamicFeedOutlined />,
          },
        ]
      : []),

    ...(roles == "ROLE_ADMIN"
      ? [
          {
            navLink: "/brands",
            name: "Brands",
            icon: <FormatBoldOutlined />,
          },
        ]
      : []),
      ...(roles == "ROLE_ADMIN"
      ? [
          {
            navLink: "/products",
            name: "Products",
            icon: <CardGiftcardOutlined />,
          },
        ]
      : []),
    ...(roles == "ROLE_ADMIN"
      ? [
          {
            navLink: "/variant",
            name: "Variants",
            icon: <PostAddOutlined />,
          },
        ]
      : []),
     
   
    ...(roles == "ROLE_ADMIN"
      ? [
          {
            navLink: "/staff",
            name: "Our Staff",
            icon: <PeopleAltOutlined />,
          },
        ]
      : []),
    ...(roles == "ROLE_ADMIN"
      ? [
          {
            navLink: "/users",
            name: "Users",
            icon: <PersonAddAltOutlined />,
          },
        ]
      : []),

    {
      navLink: "/orders",
      name: "Orders",
      icon: <ListAltOutlined />,
    },
  ];

  return (
    <ul class="space-y-1 font-medium ">
      {menuItemdata.map((i) => {
        console.log(i.childItem);
        return !i.isNested ? (
          <SideMenuItem
            navigationLink={i.navLink}
            itemName={i.name}
            icon={i.icon} 
          />
        ) : (
          <SideMenuDropdown
            childItem={i.childItem}
            id={i.id}
            parrentName={i.name}
            icon={i.icon}
          />
        );
      })}
    </ul>
  );
};

export default SideMenu;
