import React, { useEffect, useMemo, useState } from "react";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { DeleteOutline, EditNoteOutlined, Info } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import {
  ADDRESS_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  MAX_LENGTH_SIXTY_CHARS,
  MAX_LENGTH_TEN,
  MAX_LENGTH_TWO_FIFTY,
  MIN_LENGTH_TEN,
  NAME_REQUIRED_MESSAGE,
  PASSWORD_REQUIRED_MESSAGE,
  PHONE_NUMBER_INVALID_MESSAGE,
  PHONE_NUMBER_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Popover,
  Switch,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
import CustomModel from "../../components/CustomModel";
import {
  EMAIL_PATTERN,
  ONLY_DIGITS,
  PASSWORD_PATTERN,
} from "../../utills/ApplicationRegex";
import CustomHeading from "../../components/CustomHeading";
import { BASE_URL, STAFF, USER } from "../../utills/ApplicationRouting";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import { clearAuthToken } from "../../redux/BuyTown";
import { request } from "../../services/AxiosConfig";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { MaterialReactTable } from "material-react-table";
function Staff() {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.buytown);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 15,
  });

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  // get all details start
  const [staffDetails, setStaffDetails] = useState("");

  const getStaffDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${STAFF}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setStaffDetails(data);

        setIsLoading(false);
      }  else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getStaffDetails();
  }, []);

  // get all details end

  // Add code start

  const passwordValidation = (value) => {
    const regex = PASSWORD_PATTERN;
    return regex.test(value) || "Please provide valid format ";
  };

  const [isAdmin, setIsAdmin] = useState(false);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [infoPopover, setInfoPopover] = useState(null);

  const handleInfoClose = () => {
    setInfoPopover(null);
  };
  const handleIconClose = () => {
    setOpen(false);
    setIsAdmin(null);
  };

  const handleAddClickOpen = () => {
    setOpen(true);
  };

  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
      setIsAdmin(null);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = {
      name: data.name,
      email: data.email,
      contact: data.phone,
      password: data.password,

      role: isAdmin ? "ROLE_ADMIN" : "ROLE_PARTNER"
    };

    // const formData = new FormData();
    // formData.append("name", data.name);

    // formData.append("email", data.email);
    // formData.append("contact", data.phone);
    // formData.append("password", data.password);
    // formData.append("role", isAdmin ?"ROLE_ADMIN" :"ROLE_PARTNER");
    // console.log("formData:", formData);
    setIsLoading(true);

    // const headers = {
    //   "Content-Type": "multipart/form-data",
    // };

    request({ url: STAFF, method: "post", data: postData })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          handleAddClose(res.status);
          reset({ password: "" });
          getStaffDetails();

          setIsLoading(false);
        } else if (res.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.res) {
          console.log("error.response.status" + error.res.status);
        }
      });
  };

  const addAction = (
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose} backgroundColor="#62710F" />
  );
  const addModel = (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            requiredErrorMessage={NAME_REQUIRED_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_SIXTY_CHARS}
            fieldName="name"
            fieldId="name"
            fieldType="text"
            fieldLabel="Name"
            placeholder="Enter your Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <CustomInputField
            requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
            fieldPattern={EMAIL_PATTERN}
            patternErrorMessage={EMAIL_INVALID_MESSAGE}
            fieldName="email"
            fieldId="email"
            fieldType="text"
            fieldLabel="Email"
            placeholder="Enter your Email"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <CustomInputField
            requiredErrorMessage={PHONE_NUMBER_REQUIRED_MESSAGE}
            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={PHONE_NUMBER_INVALID_MESSAGE}
            minLength={10}
            minLengthErrorMessage={MIN_LENGTH_TEN}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_TEN}
            fieldName="phone"
            fieldId="phone"
            fieldType="text"
            fieldLabel="Phone Number"
            placeholder="Enter your Phone Number"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <br />
          <br />
          <Controller
            name="password"
            control={control}
            rules={{
              required: PASSWORD_REQUIRED_MESSAGE,
              validate: passwordValidation,
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                type={showPassword ? "text" : "password"}
                variant="standard"
                label={
                  <span>
                    Password <span style={{ color: "red" }}>*</span>
                  </span>
                }
                error={!!fieldState?.error}
                helperText={fieldState?.error?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                      <Tooltip
                        className="ml-2 cursor-pointer"
                        title="Your Password must contain atleast 8 to 128 characters 
                Include atleast one uppercase letter, one lowercase letter, one digit, and one special symbol"
                      >
                        {" "}
                        <Info />
                      </Tooltip>

                      <Popover
                        open={Boolean(infoPopover)}
                        anchorEl={infoPopover}
                        onClose={handleInfoClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Typography style={{ padding: "10px" }}>
                          <ul
                            style={{
                              paddingLeft: "20px",
                              margin: "0",
                              listStyleType: "circle",
                            }}
                          >
                            <li>
                              Your Password must contain at least 8 to 128
                              characters
                            </li>
                            <li>
                              Include at least one uppercase letter, one
                              lowercase letter, one digit, and one special
                              symbol
                            </li>
                          </ul>
                        </Typography>
                      </Popover>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <br />
          <br />

          <FormControlLabel
            control={
              <Switch
                checked={isAdmin}
                onChange={(event) => setIsAdmin(event.target.checked)}
              />
            }
            label="Is Admin ? : "
            labelPlacement="start"
          >
            {" "}
          </FormControlLabel>
        </Typography>
      </DialogContent>
      <DialogActions>{addAction}</DialogActions>
    </form>
  );

  // add code end

  // delete code start
  const [staffId, setStaffId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteClickOpen = (id) => {
    setStaffId(id);
    console.log(id);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onSubmitDelete = async () => {
    console.log(token);
    request({
      url: `${STAFF}/${staffId}`,
      method: "Delete",
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getStaffDetails();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="No"
        click={handleDeleteClose}
        backgroundColor="#ED4337"

      />
      <CustomButton
        fieldType="submit"
        buttonName="Yes"
        click={handleDeleteClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
    // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Do you want to delete this Staff ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  // delete code end

  // Edit code start
  const [editOpen, setEditOpen] = useState(false);
  const [updateRes, setUpdateRes] = useState([]);
  const selectedRow = updateRes;
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [isAdminData, setIsAdminData] = useState(false);

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhoneNo(event.target.value);
  };

  const handleEditClose = (status) => {
    if (status == 200) {
      setSelectedRowId(null);
      setEditOpen(false);
      setUserName('')
      setEmail('');
      setPhoneNo('');
      setIsAdminData(null);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
    getStaffDetails();
    setSelectedRowId(null);
    setUserName('')
    setEmail('');
    setPhoneNo('');
    setIsAdminData(null);
  };

  const getStaffDetailsById = async (id) => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${STAFF}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setIsLoading(true);
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;

        setUpdateRes(data.id);
        setUserName(data.name);
        setEmail(data.email);
        setPhoneNo(data.contact);
        if (data.role == "ROLE_ADMIN") {
          setIsAdminData(true);
        }

        setIsLoading(false);
      }  else {
        throw new Error("Failed to fetch data");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  const handleEditClickOpen = (selectedRow) => {
    setEditOpen(true);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.id);
    setSelectedRowId(selectedRow.original.id);
    getStaffDetailsById(selectedRow.original.id);
  };

  const onSubmitEdit = (data) => {
    if (!userName || userName.trim() === "") {
      setError("editName", {
        type: "manual",
        message: NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (userName.trim().length > 60) {
      setError("editName", {
        type: "manual",
        message: MAX_LENGTH_SIXTY_CHARS,
      });
      setIsLoading(false);
      return;
    }

    if (!email || email.trim() === "") {
      setError("editEmail", {
        type: "manual",
        message: EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!phoneNo || phoneNo.trim() === "") {
      setError("editPhone", {
        type: "manual",
        message: PHONE_NUMBER_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }



    const phoneNoDigits = phoneNo.replace(/\D/g, ""); // Remove non-numeric characters
    if (phoneNoDigits.length < 10) {
      setError("editPhone", {
        type: "manual",
        message: MIN_LENGTH_TEN,
      });
      setIsLoading(false);
      return;
    }

    if (phoneNoDigits.length > 10) {
      setError("editPhone", {
        type: "manual",
        message: MAX_LENGTH_TEN,
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(true);


    const postData = {
      name: userName,
      email: email,
      contact: phoneNo,

      role: isAdminData ? "ROLE_ADMIN" : "ROLE_PARTNER"
    };

    setIsLoading(true);


    request({ url: `${STAFF}/${selectedRow}`, method: "put", data: postData })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          handleEditClose(res.status);
          getStaffDetails();

          setIsLoading(false);
        } else if (res.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.res) {
          console.log("error.response.status" + error.res.status);
        }
      });
  };
  const editAction = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
      backgroundColor="#62710F"
    />
  );
  const editModel = (
    <form
      onSubmit={handleSubmit(onSubmitEdit)}
      className="space-y-4 md:space-y-6 "
    >
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            fieldName="editName"
            fieldId="editName"
            fieldType="text"
            fieldLabel="Name"
            placeholder="Enter your Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={userName}
            onInput={handleNameChange}
          />
          <CustomInputField
            fieldPattern={EMAIL_PATTERN}
            patternErrorMessage={EMAIL_INVALID_MESSAGE}
            fieldName="editEmail"
            fieldId="email"
            fieldType="text"
            fieldLabel="Email"
            placeholder="Enter your Email"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={email}
            onInput={handleEmailChange}
          />
          <CustomInputField
            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={PHONE_NUMBER_INVALID_MESSAGE}
            fieldName="editPhone"
            fieldId="editPhone"
            fieldType="text"
            fieldLabel="Phone Number"
            placeholder="Enter your Phone Number"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={phoneNo}
            onInput={handlePhoneChange}
          />

          <FormControlLabel
            control={
              <Switch
                checked={isAdminData}
                onChange={(event) => setIsAdminData(event.target.checked)}
              />
            }
            label="Is Admin ? : "
            labelPlacement="start"
          >
            {" "}
          </FormControlLabel>
        </Typography>
      </DialogContent>
      <DialogActions>{editAction}</DialogActions>
    </form>
  );

  // Edit code end

  const staffTable = useMemo(() => [
    {
      accessorKey: "serialNo",
      header: "Serial No",
      size: 20,
      accessorFn: (row, index) => index + 1,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 20,
    },
    {
      accessorKey: "email",
      header: "Email",
      size: 20,
    },
    {
      accessorKey: "contact",
      header: "Contact",
      size: 20,
    },
    {
      accessorKey: "role",
      header: "Role",
      accessorFn: (row) =>
        row && row.role && row.role.includes("ROLE_ADMIN") ? "Admin" : "Delivery Partner",
      size: 20,
    },
  ]);

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
        backgroundColor="#62710F"
      />
    </>
  );
  const rowActions = [
    {
      label: "Edit",
      icon: <EditNoteOutlined color="success" />,
      click: (row) => handleEditClickOpen(row),
    },
    {
      label: "Delete",
      icon: <DeleteOutline color="error" />,
      click: (row) => handleDeleteClickOpen(row.original.id),
    },
  ];

  const StickyRow = styled('tr')(({ theme }) => ({
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.paper,
    zIndex: 2,
    '&:nth-of-type(2)': {
      top: '40px', // Adjust according to your row height
    },
  }));

  return (
    <div>
      <CustomToastContainer />
      <CustomHeading title="Staff" />
      {isLoading && <CustomLoading />}
      <CustomModel
        title="Staff"
        submit={onSubmit}
        content={addModel}
        action={addAction}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
      />

      <CustomModel
        title="Delete Staff"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />

      <CustomModel
        title="Edit Staff"
        submit={onSubmitEdit}
        content={editModel}
        action={editAction}
        openStatus={selectedRowId !== null}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
      />

      <MaterialReactTable
        columns={staffTable}
        data={staffDetails}
        positionActionsColumn="last"
        enableColumnPinning={true}
        enableRowActions

        onPaginationChange={setPagination}

        state={{

          pagination,
        }}

        rowPinningDisplayMode="sticky"
        renderRowActions={({ row }) => (
          <Box>
            <IconButton onClick={() => handleEditClickOpen(row)}>
              <EditNoteOutlined color="success" />
            </IconButton>
            <IconButton onClick={() => handleDeleteClickOpen(row.original.id)}>
              <DeleteOutline color="error" />
            </IconButton>
          </Box>
        )}
        renderTopToolbarCustomActions={({ table }) => (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                padding: "8px",
                flexWrap: "wrap",
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#62710F" }}
                onClick={() => handleAddClickOpen()}
              >
                Add
              </Button>
            </Box>
          </>
        )}
        muiTableBodyProps={{
          component: 'tbody',
          sx: {
            '& tr:nth-of-type(1)': { ...StickyRow.styles },
            '& tr:nth-of-type(2)': { ...StickyRow.styles },
          }
        }}
      />

    </div>
  );
}
export default Staff;
