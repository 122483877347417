import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { clearAuthToken } from "../../redux/BuyTown";
import {
  BASE_URL,
  BASE_URL_IMAGE,
  PRODUCT,
  VARIANT,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import CustomButton from "../../components/CustomButton";
import CustomLoading from "../../components/CustomLoading";
import {
  Autocomplete,
  DialogActions,
  DialogContent,
  FormLabel,
  TextField,
  Typography,
} from "@mui/material";
import CustomInputField from "../../components/CustomInputField";
import {
  MAX_LENGTH_FIVE_DIGITS,
  MAX_LENGTH_TWO_FIFTY,
  PRICE_INVALID_MESSAGE,
  PRICE_REQUIRED_MESSAGE,
  QUANTITY_INVALID_MESSAGE,
  QUANTITY_REQUIRED_MESSAGE,
  VARIANT_NAME_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import { useForm } from "react-hook-form";
import {
  FIVE_DIGITS_TWO_DECIMAL,
  ONLY_DIGITS,
} from "../../utills/ApplicationRegex";
import CustomModel from "../../components/CustomModel";
import { request } from "../../services/AxiosConfig";
import { FileUploader } from "react-drag-drop-files";
import { DeleteOutline, EditNoteOutlined } from "@mui/icons-material";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomHeading from "../../components/CustomHeading";

function Variant() {
  const { token } = useSelector((state) => state.buytown);
  const fileTypes = ["JPG", "PNG"];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const {
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  // get brand details start
  const [variantDetails, setVariantDetails] = useState("");
  const convertToPascalCase = (str) => {
    // Check if the input string is undefined or empty
    if (str === undefined || str === "" || str === null) return "";

    // Remove underscores and convert string to Pascal case
    return str
      .replace(/_/g, " ")
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const getVariantDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${VARIANT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setVariantDetails(data);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getVariantDetails();
  }, []);

  // get brand details end

  // Add code start
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [productData, setProductData] = useState([]);
  const [homeId, setHomeId] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [product, setProduct] = useState("");
  const getProductDetails = async () => {
    try {
      // setIsLoading(true);
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${PRODUCT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const convertedData = data.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        setProductData(convertedData);
      } else {
        // setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  const handleAddClickOpen = () => {
    setOpen(true);
  };

  const handleFileChange = (file) => {
    setFile(file);
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Clear preview for non-image files
      setPreviewUrl(null);
    }
  };
  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
      setPreviewUrl(null);
      setFile("");
      setProduct(null);
    }
  };
  const handleIconClose = () => {
    setOpen(false);
    setPreviewUrl(null);
    setFile("");
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.variantName);
    formData.append("file", file);
    formData.append("productId", product);
    formData.append("quantity", data.quantity);
    formData.append("price", data.price);
    formData.append("description", data.description);
    console.log("formData:", formData);
    setIsLoading(true);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    await request({
      url: VARIANT,
      method: "post",
      data: formData,
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          handleAddClose(res.status);
          setIsLoading(false);
          getProductDetails();
          getVariantDetails();
          setProduct(null);
        } else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const addAction = (
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose}        backgroundColor="#62710F"
    />
  );

  const addModel = (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            requiredErrorMessage={VARIANT_NAME_REQUIRED_MESSAGE}
            maxLength={250}
            maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
            fieldName="variantName"
            fieldId="variantName"
            fieldType="text"
            fieldLabel="Variant Name"
            placeholder="Enter Variant Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={productData}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label={
                  <span>
                    Select Product <span style={{ color: "red" }}>*</span>
                  </span>
                }
              />
            )}
            onChange={(event, value) => {
              console.log(event);
              console.log(value);
              if (value !== null) {
                setProduct(value.value);
              }
            }}
          />

          <br />
          <br />
          <FormLabel>
            Attach your File <span style={{ color: "red" }}>*</span>
            <FileUploader
              class="py-5"
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              required
            />
            {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
            {previewUrl && (
              <img
                src={previewUrl}
                alt="Preview"
                style={{ maxWidth: "100%" }}
              />
            )}
          </FormLabel>
          <CustomInputField
            requiredErrorMessage={QUANTITY_REQUIRED_MESSAGE}
            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={QUANTITY_INVALID_MESSAGE}
            maxLength={5}
            maxLengthErrorMessage={MAX_LENGTH_FIVE_DIGITS}
            fieldName="quantity"
            fieldId="quantity"
            fieldType="text"
            fieldLabel="Quantity"
            placeholder="Enter Quantity"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <CustomInputField
            requiredErrorMessage={PRICE_REQUIRED_MESSAGE}
            fieldPattern={FIVE_DIGITS_TWO_DECIMAL}
            patternErrorMessage={PRICE_INVALID_MESSAGE}
            fieldName="price"
            fieldId="price"
            fieldType="text"
            fieldLabel="Price"
            placeholder="Enter Price"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <CustomInputField
            maxLength={250}
            maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
            fieldName="description"
            fieldId="description"
            fieldType="text"
            fieldLabel="Description"
            placeholder="Enter Description"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
        </Typography>
      </DialogContent>
      <DialogActions>{addAction}</DialogActions>
    </form>
  );

  // Add code end

  // Delete code start
  const [variantId, setVariantId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleDeleteClickOpen = (id) => {
    setVariantId(id);
    console.log(id);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onSubmitDelete = async () => {
    console.log(token);
    request({
      url: `${VARIANT}/${variantId}`,
      method: "Delete",
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getVariantDetails();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="No"
        backgroundColor="#ED4337"

        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Yes"
        click={handleDeleteClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Do you want to delete this Variant ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );
  // delete code end

  // Edit code start

  const [editBrandId, setEditBrandId] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [variantName, setVariantName] = useState("");
  const [quantityData, setQuantityData] = useState("");
  const [priceData, setPriceData] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [updateRes, setUpdateRes] = useState("");
  const [fileData, setFileData] = useState(null);
  const selectedRow = updateRes;
  const [editPreviewUrl, setEditPreviewUrl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleVariantChange = (event) => {
    setVariantName(event.target.value);
  };
  const handlequantityChange = (event) => {
    setQuantityData(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPriceData(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescriptionData(event.target.value);
  };
  const handleEditFileChange = (file) => {
    setFileData(file);
    setEditPreviewUrl(URL.createObjectURL(file));
  };

  const handleEditClose = (status) => {
    if (status == 200) {
      setSelectedRowId(null);

      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
    getVariantDetails();
    setSelectedRowId(null);
    setEditPreviewUrl(null);
    setFileData("");
  };

  const getVariantDetailsById = async (id) => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${VARIANT}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        getProductDetails();
        const data = response.data;
        console.log(data.image);
        setUpdateRes(data.id);
        setVariantName(data.name);
        const imageUrl = `${BASE_URL_IMAGE}/${data.image}`;
        // setFileData(data.image);
        setFileData(imageUrl);
        setEditPreviewUrl(imageUrl);
        // setFileData(data.image);
        setQuantityData(data.quantity);
        setPriceData(data.price);
        setDescriptionData(data.description);
        setProduct(data.productId);
      }  else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  const handleEditClickOpen = (selectedRow) => {
    setEditOpen(true);

    setSelectedRowId(selectedRow.original.id);
    getVariantDetailsById(selectedRow.original.id);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.id);
  };

  const onSubmitEdit = (e) => {
    setIsLoading(true);
    if (!variantName || variantName.trim() === "") {
      setError("editVariantName", {
        type: "manual",
        message: VARIANT_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (variantName) {
      if (!variantName.trim().length > 250) {
        setError("editVariantName", {
          type: "manual",
          message: MAX_LENGTH_TWO_FIFTY,
        });
        return;
      }
      setIsLoading(false);
    }

    if (!quantityData || quantityData.trim() === "") {
      setError("editQuantity", {
        type: "manual",
        message: QUANTITY_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!quantityData.trim().length > 5) {
      setError("editQuantity", {
        type: "manual",
        message: MAX_LENGTH_FIVE_DIGITS,
      });
      return;
    }

    if (!priceData || priceData.trim() === "") {
      setError("editPrice", {
        type: "manual",
        message: PRICE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (descriptionData) {
      if (!descriptionData.trim().length > 250) {
        setError("editDescription", {
          type: "manual",
          message: MAX_LENGTH_TWO_FIFTY,
        });
        setIsLoading(false);
        return;
      }
    }

    const formData = new FormData();

    formData.append("name", variantName.trim());
    formData.append("file", fileData);
    formData.append("productId", product);
    formData.append("quantity", quantityData);
    formData.append("price", priceData);
    formData.append("description", descriptionData);

    const storedToken = token;

    console.log(storedToken);
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({
      url: `${BASE_URL}${VARIANT}/${selectedRow}`,
      data: formData,
      method: "put",
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleEditClose(res.status);
          getVariantDetails();
        }
      })
      .catch((error) => {      
        setIsLoading(false);
        console.log("error response",error.response.status);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const editAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Update "
        backgroundColor="#62710F"
        click={handleEditClose}
      />
    </>
  );

  const getProductValue = (product) => {
    console.log("product :" + product);
    console.log(productData);
    const value = productData.filter((data) => data.value == product);
    console.log(value.length);
    if (value.length > 0) {
      console.log("success");
      return value[0].label;
    } else {
      return null;
    }
  };
  const editModel = (
    <form onSubmit={handleSubmit(onSubmitEdit)}>
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            maxLength={250}
            maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
            fieldName="editVariantName"
            fieldId="editVariantName"
            fieldType="text"
            fieldLabel="Brand Name"
            placeholder="Enter your Brand  Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={variantName}
            onInput={handleVariantChange}
          />

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={productData}
            value={getProductValue(product)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label="Select Product"
              />
            )}
            onChange={(event, value) => {
              if (value !== null) {
                setProduct(value.value);
              }
            }}
          />

          <br />
          <br />
          <FormLabel>
            Attach your File{" "}
            <FileUploader
              class="py-5"
              handleChange={handleEditFileChange}
              name="file"
              types={fileTypes}
              //   required
            />
            {editPreviewUrl && (
              <div>
                <p>Selected File: {fileData.name}</p>
                <img
                  src={editPreviewUrl}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </div>
            )}
          </FormLabel>
          <CustomInputField
            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={QUANTITY_INVALID_MESSAGE}
            fieldName="editQuantity"
            fieldId="editQuantity"
            fieldType="text"
            fieldLabel="Quantity"
            placeholder="Enter your Quantity"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={quantityData}
            onInput={handlequantityChange}
          />
          <CustomInputField
            fieldPattern={FIVE_DIGITS_TWO_DECIMAL}
            patternErrorMessage={PRICE_INVALID_MESSAGE}
            fieldName="editPrice"
            fieldId="editPrice"
            fieldType="text"
            fieldLabel="Price"
            placeholder="Enter Price"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={priceData}
            onInput={handlePriceChange}
          />
          <CustomInputField
            fieldName="editDescription"
            fieldId="editDescription"
            fieldType="text"
            fieldLabel="Description"
            placeholder="Enter Description"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={descriptionData}
            onInput={handleDescriptionChange}
          />
        </Typography>
      </DialogContent>

      <DialogActions>{editAction}</DialogActions>
    </form>
  );

  // Edit code end

  const variantTable = useMemo(() => [
    {
      accessorKey: "serialNo",
      header: "Serial No",
      size: 20,
      accessorFn: (row, index) => index + 1,
    },
    {
      accessorKey: "name",
      header: "Variant Name",
      size: 20,
      accessorFn: (row) => row && row.name,
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      size: 20,
      accessorFn: (row) => row && row.quantity,
    },
    {
      accessorKey: "price",
      header: "Price",
      size: 20,
      accessorFn: (row) => row && row.price,
    },
    {
      accessorKey: "description",
      header: "Description",
      size: 20,
      accessorFn: (row) => (row && row.description ? row.description : ""),
    },
    // {
    //   accessorKey: "categoryName",
    //   header: "product Name",
    //   size: 20,
    //   accessorFn: (row) =>
    //     convertToPascalCase(
    //       `${row.categoryDetails ? row.categoryDetails.name : ""}  `
    //     ),
    // },
    {
      accessorKey: "image",
      header: "Image",
      size: 20,
      Cell: ({ row }) => (
        <img
          src={`${BASE_URL_IMAGE}/${row.original.image}`}
          alt={row.original.image}
          style={{ width: 150, height: 150 }}
        />
      ),
    },
  ]);

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        backgroundColor="#62710F"
        click={handleAddClickOpen}
      />
    </>
  );
  const rowActions = [
    {
      label: "Edit",
      icon: <EditNoteOutlined color="success" />,
      click: (row) => handleEditClickOpen(row),
    },
    {
      label: "Delete",
      icon: <DeleteOutline color="error" />,
      click: (row) => handleDeleteClickOpen(row.original.id),
    },
  ];
  return (
    <div>
      <CustomToastContainer/>
      <CustomHeading title="Variants" />
      {isLoading &&<CustomLoading/>}
      <CustomMaterialTable
        columns={variantTable}
        data={variantDetails}
        rowActions={rowActions}
        enableRowActions={true}
        actionButtons={tableActionButtons}
      />

      <CustomModel
        title="Variant"
        submit={onSubmit}
        content={addModel}
        action={addAction}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomModel
        title="Delete Variant"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        isLoading={isLoading}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />

      <CustomModel
        title="Edit Variant"
        submit={onSubmitEdit}
        content={editModel}
        action={editAction}
        openStatus={selectedRowId !== null}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
        isLoading={isLoading}
      />
    </div>
  );
}

export default Variant;
