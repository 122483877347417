import React from 'react'
import LoginForm from '../../components/login/LoginForm'

function LoginPage() {
  return (
    <div>
        <LoginForm/>
    </div>
  )
}

export default LoginPage
