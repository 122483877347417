import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

function CustomToastContainer() {
  return (
   <ToastContainer
   position="top-center" 
   autoClose={3000} 
   limit = {3}
   transition={Slide} 
   closeOnClick
    />
  )
}

export default CustomToastContainer
