import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
import CustomButton from "../../components/CustomButton";
import {
  CONFIRM_PASSWORD_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  NEW_PASSWORD_CURRENT_PASSWORD_CHECKING,
  NEW_PASSWORD_REQUIRED_MESSAGE,
  OTP_INVALID_MESSAGE,
  OTP_REQUIRED_MESSAGE,
  PASSWORD_REQUIRED_MESSAGE,
  PHONE_NUMBER_INVALID_MESSAGE,
  PHONE_NUMBER_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  EMAIL_PATTERN,
  ONLY_DIGITS,
  PASSWORD_PATTERN,
} from "../../utills/ApplicationRegex";
import { useNavigate } from "react-router";
import {
  BASE_URL,
  FORGOT_CHANGE_PASSWORD,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_OTP,
} from "../../utills/ApplicationRouting";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setToken, setUserLoginDetails } from "../../redux/BuyTown";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import { Link } from "react-router-dom";
import logo from "../../assets/image/buytown.png";
import CustomModel from "../../components/CustomModel";
import { InfoOutlined } from "@mui/icons-material";

function ForgotPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData = {
      userName: data.email,
      role: "ROLE_PARTNER",
    };
    setIsLoading(true);
    axios
      .post(`${BASE_URL}${FORGOT_PASSWORD}`, postData)
      .then((response) => {
        console.log(response);
       

        if (response.status === 200) {
          setIsLoading(false);
          toast.success(response.data);
          setOtpOpen(true);
        }
      })
      .catch((error) => {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
        }, 8000);
        if (error.response && error.response.status !== 200) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
      });
  };

  // OTP handling
  const [OtpOpen, setOtpOpen] = useState(false);
  const [otpData, setOtpData] = useState("");

  const handleOtpClose = (status) => {
    if (status === 200) {
      setOtpOpen(false);
    }
  };
  const handleOtpIconClose = () => {
    setOtpOpen(false);
  };

  const onOtpSubmit = (data) => {
    setIsLoading(true);

    const postData = {
      userName: data.email,
      otp: data.otp,
      role: "ROLE_PARTNER",
    };
    console.log("postdata", postData);

    axios
      .post(`${BASE_URL}${FORGOT_PASSWORD_OTP}`, postData)
      .then((response) => {
        console.log(response);
        setIsLoading(true);

        if (response.status === 200) {
          setIsLoading(false);
          toast.success(response.data);
          setOtpOpen(false);
          setOpen(true);
        }
      })
      .catch((error) => {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          setOtpOpen(false);
        }, 8000);
        if (error.response && error.response.status !== 200) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
      });
  };

  const editOtpStatus = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Update "
        click={handleOtpClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const editOtpStatusModel = (
    <form onSubmit={handleSubmit(onOtpSubmit)}>
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            requiredErrorMessage={OTP_REQUIRED_MESSAGE}
            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={OTP_INVALID_MESSAGE}
            fieldName="otp"
            fieldId="otp"
            fieldType="text"
            fieldLabel="OTP"
            placeholder="Enter OTP"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
        </Typography>
      </DialogContent>
      <DialogActions>{editOtpStatus}</DialogActions>
    </form>
  );

  // Change password handling
  const [Open, setOpen] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [infoPopover, setInfoPopover] = useState(null);

  const handleInfoClick = (event) => {
    setInfoPopover(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoPopover(null);
  };

  const handleClose = (status) => {
    if (status === 200) {
      setOpen(false);
    }
  };
  const handleIconClose = () => {
    setOpen(false);
  };

  const passwordValidation = (value) => {
    const regex = PASSWORD_PATTERN;
    return regex.test(value) || "Please provide valid format ";
  };

  const onChangeSubmit = (data) => {
    setIsLoading(true);

    const postData = {
      userName: data.email,
      password: data.newPassword,
      role: "ROLE_PARTNER"
    };
    console.log("postdata", postData);
    if (data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: NEW_PASSWORD_CURRENT_PASSWORD_CHECKING,
      });
      setIsLoading(false);
      return;
    }

    axios
      .post(`${BASE_URL}${FORGOT_CHANGE_PASSWORD}`, postData)
      .then((response) => {
        console.log(response);
        setIsLoading(true);

        if (response.status === 200) {
          setIsLoading(false);
          toast.success(response.data);
          setOpen(false);
          navigate("/");
        }
      })
      .catch((error) => {
        setIsLoading(true);
        setTimeout(() => {
          setIsLoading(false);
          setOpen(false);
        }, 8000);
        if (error.response && error.response.status !== 200) {
          console.log(error.response.data.message);
          toast.error(error.response.data.message);
        }
      });
  };

  const editStatus = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Update "
        click={handleClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const editStatusModel = (
    <form onSubmit={handleSubmit(onChangeSubmit)}>
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <section className="grid md:grid-cols-2 xl:grid-cols-1 gap-5">
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: NEW_PASSWORD_REQUIRED_MESSAGE,
                validate: passwordValidation,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type={newPassword ? "text" : "password"}
                  variant="standard"
                  label="New Password"
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setNewPassword(!newPassword)}
                          edge="end"
                        >
                          {newPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>

                        <Tooltip
                          className="ml-2 cursor-pointer"
                          title="Your Password must contain at least 8 to 128 characters. Include at least one uppercase letter, one lowercase letter, one digit, and one special symbol."
                        >
                          <InfoOutlined />
                        </Tooltip>
                        <Popover
                          open={Boolean(infoPopover)}
                          anchorEl={infoPopover}
                          onClose={handleInfoClose}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        >
                          <Typography style={{ padding: "10px" }}>
                            <ul
                              style={{
                                paddingLeft: "20px",
                                margin: "0",
                                listStyleType: "circle",
                              }}
                            >
                              <li>Your Password must contain at least 8 to 128 characters</li>
                              <li>Include at least one uppercase letter, one lowercase letter, one digit, and one special symbol</li>
                            </ul>
                          </Typography>
                        </Popover>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: CONFIRM_PASSWORD_REQUIRED_MESSAGE,
                validate: passwordValidation,
              }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type={confirmPassword ? "text" : "password"}
                  variant="standard"
                  label="Confirm Password"
                  error={!!fieldState?.error}
                  helperText={fieldState?.error?.message}
                  onPaste={(e) => e.preventDefault()}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setConfirmPassword(!confirmPassword)}
                          edge="end"
                        >
                          {confirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </section>
        </Typography>
      </DialogContent>
      <DialogActions>{editStatus}</DialogActions>
    </form>
  );

  return (
    <>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <div className="flex justify-center items-center min-h-screen bg-white">
        <section className="w-full max-w-md bg-white rounded-lg shadow p-6 space-y-6">
          <div className="flex items-center justify-center mb-6">
            <img className="w-44 h-14" src={logo} alt="BuyTown" />
          </div>
          <h6 className="font-bold leading-tight tracking-tight text-gray-900  text-center">
            Forgot Password
          </h6>
          <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
            <CustomInputField
              requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
              fieldPattern={EMAIL_PATTERN}
              patternErrorMessage={EMAIL_INVALID_MESSAGE}
              fieldName="email"
              fieldId="email"
              fieldType="text"
              fieldLabel="Email"
              placeholder="Enter your Email"
              fieldControl={control}
              fieldError={errors}
              requiredIcon="*"
            />
            <div className="flex items-center justify-center">
              <CustomButton
                fieldType="submit"
                buttonName="Send OTP"
                backgroundColor="#62710F"
                disableFocusRipple={true}
              />
             
            </div>
            <hr />
            <div className="flex items-center justify-center">
              <Link to="/" className="text-sm font-medium text-primary-600 hover:underline">
                Login
              </Link>
            </div>
          </form>
        </section>
      </div>
      <CustomModel
        title="OTP"
        submit={onOtpSubmit}
        content={editOtpStatusModel}
        action={editOtpStatus}
        openStatus={OtpOpen}
        closeStatus={handleOtpClose}
        iconCloseStatus={handleOtpIconClose}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomModel
        title="Change Password"
        submit={onChangeSubmit}
        content={editStatusModel}
        action={editStatus}
        openStatus={Open}
        closeStatus={handleClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
        isLoading={isLoading}
      />
    </>
  );
}

export default ForgotPassword;
