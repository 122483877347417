import { Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SideMenuItem = (props) => {
  const location = useLocation();
  return (
    <>
      <li
        class={`rounded-lg  ${
          props.navigationLink === location.pathname ? "bg-[#62710F]" : ""
        }`}
      >
        <Link
          to={props.navigationLink}
          class={`flex items-center px-2 py-[7px] text-gray-900 rounded-lg  group current:bg-gray-200 ${
            props.navigationLink === location.pathname ? "text-white" : ""
          }`}
        >
          <span
            class={` ${
              props.navigationLink === location.pathname ? "text-white" : ""
            }`}
          >
            {props.icon}
          </span>

          <Typography class="ms-3  text-regular text-sm" variant="h5">
            {props.itemName}
          </Typography>
        </Link>
      </li>
    </>
  );
};

export default SideMenuItem;
