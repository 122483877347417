import { createSlice } from "@reduxjs/toolkit";

export const buytown = createSlice({
  name: "buytown",
  initialState: {
    token: null,

    userLoginDetails: "",
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },

    setUserLoginDetails: (state, action) => {
      state.userLoginDetails = action.payload;
    },

    clearAuthToken: (state) => {
      state.token = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setToken,
  setUserLoginDetails,
  clearAuthToken,
  

} = buytown.actions;

export default buytown.reducer;
