import React, { useEffect, useMemo, useState } from "react";
import CustomMaterialTable from "../../components/CustomMaterialTable";
import { DeleteOutline, EditNoteOutlined } from "@mui/icons-material";
import CustomButton from "../../components/CustomButton";
import {
  ADDRESS_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  MAX_LENGTH_SIXTY_CHARS,
  MAX_LENGTH_TEN,
  MAX_LENGTH_TWO_FIFTY,
  MIN_LENGTH_TEN,
  NAME_REQUIRED_MESSAGE,
  PHONE_NUMBER_INVALID_MESSAGE,
  PHONE_NUMBER_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import CustomLoading from "../../components/CustomLoading";
import CustomToastContainer from "../../components/CustomToastContainer";
import { DialogActions, DialogContent, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import CustomInputField from "../../components/CustomInputField";
import CustomModel from "../../components/CustomModel";
import { EMAIL_PATTERN, ONLY_DIGITS } from "../../utills/ApplicationRegex";
import CustomHeading from "../../components/CustomHeading";
import { BASE_URL, USER } from "../../utills/ApplicationRouting";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import axios from "axios";
import { clearAuthToken } from "../../redux/BuyTown";
import { request } from "../../services/AxiosConfig";

function User() {
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useSelector((state) => state.buytown);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const handleIconClose = () => {
    setOpen(false);
  };

  // get all details start
  const [usersDetails, setUsersDetails] = useState("");

  const getUserDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${USER}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setUsersDetails(data);

        setIsLoading(false);
      } else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  // get all details end

  // Add code start

  const [open, setOpen] = useState(false);

  const handleAddClickOpen = () => {
    setOpen(true);
  };

  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = {
      name: data.name,
      // email: data.email,
      contact: data.phone,
      address: data.address,
    };

    request({ url: USER, method: "post", data: postData })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          handleAddClose(res.status);
          getUserDetails();

          setIsLoading(false);
        } else if (res.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.res) {
          console.log("error.response.status" + error.res.status);
        }
      });
  };

  const addAction = (
    <CustomButton fieldType="submit" buttonName="Add" click={handleAddClose} backgroundColor="#62710F"
    />
  );
  const addModel = (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            requiredErrorMessage={NAME_REQUIRED_MESSAGE}
            maxLength={60}
            maxLengthErrorMessage={MAX_LENGTH_SIXTY_CHARS}
            fieldName="name"
            fieldId="name"
            fieldType="text"
            fieldLabel="Name"
            placeholder="Enter your Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          {/* <CustomInputField
            requiredErrorMessage={EMAIL_REQUIRED_MESSAGE}
            fieldPattern={EMAIL_PATTERN}
            patternErrorMessage={EMAIL_INVALID_MESSAGE}
            fieldName="email"
            fieldId="email"
            fieldType="text"
            fieldLabel="Email"
            placeholder="Enter your Email"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          /> */}
          <CustomInputField
            requiredErrorMessage={PHONE_NUMBER_REQUIRED_MESSAGE}
            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={PHONE_NUMBER_INVALID_MESSAGE}
            minLength={10}
            minLengthErrorMessage={MIN_LENGTH_TEN}
            maxLength={10}
            maxLengthErrorMessage={MAX_LENGTH_TEN}
            fieldName="phone"
            fieldId="phone"
            fieldType="text"
            fieldLabel="Phone Number"
            placeholder="Enter your Phone Number"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <CustomInputField
            requiredErrorMessage={ADDRESS_REQUIRED_MESSAGE}
            maxLength={250}
            maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
            fieldName="address"
            fieldId="address"
            fieldType="text"
            fieldLabel="Address"
            placeholder="Enter your Address"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            multiline={true}
            maxRows={5}
          />
        </Typography>
      </DialogContent>
      <DialogActions>{addAction}</DialogActions>
    </form>
  );

  // add code end

  // delete code start
  const [userId, setUserId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteClickOpen = (id) => {
    setUserId(id);
    console.log(id);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onSubmitDelete = async () => {
    console.log(token);
    request({
      url: `${USER}/${userId}`,
      method: "Delete",
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getUserDetails();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response) {
          console.log("error.response.status" + error.response.status);
        }
      });
  };

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="No"
        click={handleDeleteClose}
        backgroundColor="#ED4337"

      />
      <CustomButton
        fieldType="submit"
        buttonName="Yes"
        click={handleDeleteClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
      // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Do you want to delete this User ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  // delete code end

  // Edit code start
  const [editOpen, setEditOpen] = useState(false);
  const [updateRes, setUpdateRes] = useState([]);
  const selectedRow = updateRes;
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhoneNo(event.target.value);
  };
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const getUserDetailsById = async (id) => {
    try {
      setIsLoading(true);
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${USER}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;

        setUpdateRes(data.id);
        setUserName(data.name);
        // setEmail(data.email);
        setPhoneNo(data.contact);
        setAddress(data.address);
        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch data");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };
  const handleEditClose = (status) => {
    if (status == 200) {
      setSelectedRowId(null);
      setAddress("");
      setPhoneNo("");
      setUserName("");
      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
    getUserDetails();
    setAddress("");
    setPhoneNo("");
    setUserName("");
    setSelectedRowId(null);
  };
  const handleEditClickOpen = (selectedRow) => {
    setEditOpen(true);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.id);
    setSelectedRowId(selectedRow.original.id);
    getUserDetailsById(selectedRow.original.id);
  };

  const onSubmitEdit = (data) => {
    if (!userName || userName.trim() === "") {
      setError("editName", {
        type: "manual",
        message: NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (userName.trim().length > 60) {
      setError("editName", {
        type: "manual",
        message: MAX_LENGTH_SIXTY_CHARS,
      });
      setIsLoading(false);
      return;
    }

    if (!phoneNo || phoneNo.trim() === "") {
      setError("editPhone", {
        type: "manual",
        message: PHONE_NUMBER_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!address || address.trim() === "") {
      setError("editAddress", {
        type: "manual",
        message: ADDRESS_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (address.trim().length > 250) {
      setError("editAddress", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY,
      });
      setIsLoading(false);
      return;
    }

    const phoneNoDigits = phoneNo.replace(/\D/g, ""); // Remove non-numeric characters
    if (phoneNoDigits.length < 10) {
      setError("editPhone", {
        type: "manual",
        message: MIN_LENGTH_TEN,
      });
      setIsLoading(false);
      return;
    }

    if (phoneNoDigits.length > 10) {
      setError("editPhone", {
        type: "manual",
        message: MAX_LENGTH_TEN,
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const postData = {
      name: userName,
      // email: email,
      contact: phoneNo,
      address: address,
    };

    request({ url: `${USER}/${selectedRow}`, method: "put", data: postData })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          handleEditClose(res.status);
          getUserDetails();

          setIsLoading(false);
        } else if (res.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.res) {
          console.log("error.response.status" + error.res.status);
        }
      });
  };
  const editAction = (
    <CustomButton
      fieldType="submit"
      buttonName="Update"
      click={handleEditClose}
      backgroundColor="#62710F"
    />
  );
  const editModel = (
    <form
      onSubmit={handleSubmit(onSubmitEdit)}
      className="space-y-4 md:space-y-6 "
    >
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            fieldName="editName"
            fieldId="editName"
            fieldType="text"
            fieldLabel="Name"
            placeholder="Enter your Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={userName ? userName : ""}
            onInput={handleNameChange}
          />
          {/* <CustomInputField
            fieldPattern={EMAIL_PATTERN}
            patternErrorMessage={EMAIL_INVALID_MESSAGE}
            fieldName="editEmail"
            fieldId="email"
            fieldType="text"
            fieldLabel="Email"
            placeholder="Enter your Email"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={email}
            onInput={handleEmailChange}
          /> */}
          <CustomInputField
            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={PHONE_NUMBER_INVALID_MESSAGE}
            fieldName="editPhone"
            fieldId="editPhone"
            fieldType="text"
            fieldLabel="Phone Number"
            placeholder="Enter your Phone Number"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={phoneNo}
            onInput={handlePhoneChange}
          />
          <CustomInputField
            fieldName="editAddress"
            fieldId="editAddress"
            fieldType="text"
            fieldLabel="Address"
            placeholder="Enter your Address"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={address}
            multiline={true}
            maxRows={5}
            onInput={handleAddressChange}
          />
        </Typography>
      </DialogContent>
      <DialogActions>{editAction}</DialogActions>
    </form>
  );

  // Edit code end

  const userTable = useMemo(() => [
    {
      accessorKey: "serialNo",
      header: "Serial No",
      size: 20,
      accessorFn: (row, index) => index + 1,
    },
    {
      accessorKey: "name",
      header: "Name",
      size: 20,
    },
    // {
    //   accessorKey: "email",
    //   header: "Email",
    //   size: 20,
    // },
    {
      accessorKey: "contact",
      header: "Contact",
      size: 20,
    },
    {
      accessorKey: "address",
      header: "Address",
      size: 20,
    },
  ]);

  const tableActionButtons = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="Add"
        click={handleAddClickOpen}
        backgroundColor="#62710F"
      />
    </>
  );
  const rowActions = [
    {
      label: "Edit",
      icon: <EditNoteOutlined color="success" />,
      click: (row) => handleEditClickOpen(row),
    },
    {
      label: "Delete",
      icon: <DeleteOutline color="error" />,
      click: (row) => handleDeleteClickOpen(row.original.id),
    },
  ];

  return (
    <div>
      <CustomToastContainer />
      <CustomHeading title="Users" />
      {isLoading && <CustomLoading/>}
      <CustomModel
        title="User"
        submit={onSubmit}
        content={addModel}
        action={addAction}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
        isLoading={isLoading}
      />

      <CustomModel
        title="Delete User"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        isLoading={isLoading}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />

      <CustomModel
        title="Edit User"
        submit={onSubmitEdit}
        content={editModel}
        action={editAction}
        openStatus={editOpen}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
        isLoading={isLoading}
      />

      <CustomMaterialTable
        columns={userTable}
        data={usersDetails}
        rowActions={rowActions}
        enableRowActions={true}
        actionButtons={tableActionButtons}
      />
    </div>
  );
}

export default User;
