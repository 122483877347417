import { TextField } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

function CustomInputField(props) {
  const CustomInputLabel = () => (
    <label>
      {props.fieldLabel}
      {props.requiredIcon && <span style={{ color: "red" }}> {props.requiredIcon} </span>}
    </label>
  );
  return (
    <Controller
      name={props.fieldName}
      control={props.fieldControl}
      defaultValue={props.defaultValue}
      rules={{
        required: props.requiredErrorMessage,
        pattern: {
          value: props.fieldPattern,
          message: props.patternErrorMessage,
        },

        minLength: {
          value: props.minLength,
          message: props.minLengthErrorMessage,
        },
        maxLength: {
          value: props.maxLength,
          message: props.maxLengthErrorMessage,
        },
        validate:
          props.validate && typeof props.validate === "function"
            ? props.validate
            : undefined,
      }}
      render={({ field, fieldState }) => (
        <>
          <TextField
            fullWidth
            {...field}
            label={
              <CustomInputLabel
                label={props.fieldLabel}
                requiredIcon={props.fieldRequiredIcon}
              />
            }
            type={props.fieldType}
            id={props.fieldId}
            margin="dense"
            variant="standard"
         
            disabled={props.disabled}
            multiline={props.multiline}
            maxRows={props.maxRows}
            value={props.value}
            onInput={props.onInput}
            
            onBlur={(e) => field.onChange(e.target.value.trim())}
            InputProps={{
              readOnly: (props.readOnly),
            }}
            placeholder={props.placeholder}
            inputProps={{
              style: {
                fontSize: 16,
                fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
              },
            }}
            InputLabelProps={{
             
              style: {
                fontSize: 16,
                fontFamily: `'Inter','Open Sans','Proxima Nova Regular','Helvetica','Arial','sans-serif'`,
              },
            }}
            error={fieldState.invalid}
            helperText={fieldState.invalid ? fieldState.error?.message : ""}
          />
        </>
      )}
    />
  );
}

export default CustomInputField;
