import React, { useEffect, useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomButton from "../../components/CustomButton";
import CustomLoading from "../../components/CustomLoading";
import CustomInputField from "../../components/CustomInputField";
import { Card, CardContent, FormLabel } from "@mui/material";
import {
  BASE_URL,
  BASE_URL_IMAGE,
  PROFILE,
  PROFILE_ADMIN,
  STAFF,
  USER,
} from "../../utills/ApplicationRouting";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  ADDRESS_REQUIRED_MESSAGE,
  EMAIL_INVALID_MESSAGE,
  EMAIL_REQUIRED_MESSAGE,
  MAX_LENGTH_SIXTY_CHARS,
  MAX_LENGTH_TEN,
  MAX_LENGTH_TWO_FIFTY,
  MIN_LENGTH_TEN,
  NAME_REQUIRED_MESSAGE,
  PHONE_NUMBER_INVALID_MESSAGE,
  PHONE_NUMBER_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import { useForm } from "react-hook-form";
import { clearAuthToken, setUserLoginDetails } from "../../redux/BuyTown";
import { EMAIL_PATTERN, ONLY_DIGITS } from "../../utills/ApplicationRegex";
import { request } from "../../services/AxiosConfig";

import ImageUploader from "react-images-uploading";
import "react-image-upload/dist/index.css";
import {
  BikeScooterSharp,
  CameraAltOutlined,
  CancelOutlined,
} from "@mui/icons-material";
import { FileUploader } from "react-drag-drop-files";

function Profile() {
  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();
  const { token, userLoginDetails } = useSelector((state) => state.buytown);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");
  // const [userId,setUserId] = useState("");

  const userId = userLoginDetails.id;
  
  const [fileData, setFileData] = useState(null);
  const [editPreviewUrl, setEditPreviewUrl] = useState(null);
  // const roles = userLoginDetails.role[0];

  // console.log("roles",userLoginDetails.role[0]);

  const handleChangePassword = () => {
    navigate("/changepassword");
  };
  const handleBack = () => {
    navigate("/");
  };

  const handleProfilePicUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileData(file);
      setEditPreviewUrl(URL.createObjectURL(file));
    }
  };
  console.log("userId", userId);

  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhoneNo(event.target.value);
  };
  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };
  const [file, setFile] = useState(null);
  const fileTypes = ["JPG", "PNG"];
  

 
  const ProfileDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${PROFILE}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });

      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;

        dispatch(setUserLoginDetails(data));

        console.log("data", data);
        console.log("image", data.image);
        // setUserId(data.id);
        setUserName(data.name);
        setEmail(data.email);
        setPhoneNo(data.contact);
        
        const imageUrl = (data.image);
        // setFileData(data.image);
        setFileData(imageUrl);
        setEditPreviewUrl(imageUrl);
        setIsLoading(false);
      } else {
        setTimeout(() => {
          setIsLoading(false);
        }, 8000);
      }
    } catch (error) {
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    ProfileDetails();
  }, [userId]);

  const onSubmit = (data) => {
    if (!userName || userName.trim() === "") {
      setError("editName", {
        type: "manual",
        message: NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (userName.trim().length > 60) {
      setError("editName", {
        type: "manual",
        message: MAX_LENGTH_SIXTY_CHARS,
      });
      setIsLoading(false);
      return;
    }

    if (!email || email.trim() === "") {
      setError("editEmail", {
        type: "manual",
        message: EMAIL_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (!phoneNo || phoneNo.trim() === "") {
      setError("editPhone", {
        type: "manual",
        message: PHONE_NUMBER_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    const phoneNoDigits = phoneNo.replace(/\D/g, ""); // Remove non-numeric characters
    if (phoneNoDigits.length < 10) {
      setError("editPhone", {
        type: "manual",
        message: MIN_LENGTH_TEN,
      });
      setIsLoading(false);
      return;
    }

    if (phoneNoDigits.length > 10) {
      setError("editPhone", {
        type: "manual",
        message: MAX_LENGTH_TEN,
      });
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append("name", userName);

    formData.append("email", email);
    formData.append("contact", phoneNo);
    if (fileData instanceof File) {
      formData.append("file", fileData);
    }

    console.log("formData:", formData);
    setIsLoading(true);
    // let profileUrl ;
    // if (roles.includes("ROLE_ADMIN") || roles.includes("ROLE_PARTNER")) {
    //   profileUrl = `${BASE_URL}${PROFILE_ADMIN}`;
    // } else {
    //   profileUrl = `${BASE_URL}${PROFILE}`;
    // }

    const headers = {
      "Content-Type": "multipart/form-data",
    };
    // console.log("profileUrl",profileUrl);
    // return;
    request({
      url: PROFILE,
      method: "put",
      data: formData,
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          ProfileDetails();

          setIsLoading(false);
        } 
      })
      .catch((error) => {
        console.log(error.res);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
        
      });
  };

  return (
    <div>
      <div class="flex flex-col md:flex-row">
        <CustomToastContainer />

        <CustomHeading title="Edit Profile" />

        <div class="md:full md:pl-4 sm:text-left md:text-right sm:w-full text-lg font-sans mb-2 space-x-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleBack}
            backgroundColor="#ED4337"
          />
          <CustomButton
            fieldType="button"
            buttonName="Change Password"
            click={handleChangePassword}
            backgroundColor="#62710F"
          />
        </div>
      </div>
      <Card>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="space-y-4 md:space-y-6 "
        >
          <Card>
            <CardContent>
              <div className=" mb-4">
                {editPreviewUrl && (
                  <img
                    src={`${editPreviewUrl}`}
                   
                    className="w-44 h-44 rounded-full mr-4"
                  />
                )}
                <label htmlFor="upload-profile-pic" className="cursor-pointer">
                  <CameraAltOutlined fontSize="medium" />
                </label>
                <input
                  type="file"
                  id="upload-profile-pic"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleProfilePicUpload}
                />
                {/* {editPreviewUrl && (
                  <CancelOutlined
                    fontSize="large"
                    className="cursor-pointer ml-2"
                    onClick={() => {
                     setEditPreviewUrl(null);
                     setFileData(null);
                    }}
                  />
                )} */}
              </div>
            </CardContent>
          </Card>
          <br></br>
          {isLoading && <CustomLoading />}
          <CardContent>
            <section class="grid md:grid-cols-2 xl:grid-cols-2 gap-5">
              <CustomInputField
                fieldName="editName"
                fieldId="editName"
                fieldType="text"
                fieldLabel="Name"
                placeholder="Enter your Name"
                fieldControl={control}
                fieldError={errors}
                requiredIcon="*"
                value={userName ? userName : ""}
                onInput={handleNameChange}
              />
              <CustomInputField
                fieldPattern={EMAIL_PATTERN}
                patternErrorMessage={EMAIL_INVALID_MESSAGE}
                fieldName="editEmail"
                fieldId="email"
                fieldType="text"
                fieldLabel="Email"
                placeholder="Enter your Email"
                fieldControl={control}
                fieldError={errors}
                requiredIcon="*"
                value={email}
                disabled={true}
                onInput={handleEmailChange}
              />
              <CustomInputField
                fieldPattern={ONLY_DIGITS}
                patternErrorMessage={PHONE_NUMBER_INVALID_MESSAGE}
                fieldName="editPhone"
                fieldId="editPhone"
                fieldType="text"
                fieldLabel="Phone Number"
                placeholder="Enter your Phone Number"
                fieldControl={control}
                fieldError={errors}
                requiredIcon="*"
                value={phoneNo}
                onInput={handlePhoneChange}
              />
            
            </section>

            <div class="text-center mt-10">
              <CustomButton
                fieldType="submit"
                buttonName="Update"
                backgroundColor="#62710F"
              />
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default Profile;
