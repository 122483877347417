import React, { useState } from "react";
import CustomHeading from "../../components/CustomHeading";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import CustomButton from "../../components/CustomButton";
import {
  Card,
  CardContent,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  CONFIRM_PASSWORD_REQUIRED_MESSAGE,
  CURRENT_AND_NEW_PASSWORD_CHECKING,
  CURRENT_PASSWORD_REQUIRED_MESSAGE,
  NEW_PASSWORD_CURRENT_PASSWORD_CHECKING,
  NEW_PASSWORD_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import { PASSWORD_PATTERN } from "../../utills/ApplicationRegex";
import { Info, InfoOutlined } from "@mui/icons-material";
import { CHANGE_PASSWORD } from "../../utills/ApplicationRouting";
import { clearAuthToken } from "../../redux/BuyTown";
import { request } from "../../services/AxiosConfig";
function ChangePassword() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [infoPopover, setInfoPopover] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    navigate(-1);
  };

  const handleInfoClick = (event) => {
    setInfoPopover(event.currentTarget);
  };

  const handleInfoClose = () => {
    setInfoPopover(null);
  };

  const { handleSubmit, control, reset, setError } = useForm();

  const passwordValidation = (value) => {
    const regex = PASSWORD_PATTERN;
    return regex.test(value) || "Please provide valid format ";
  };
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    setIsLoading(true);
    const postData = {
      currentPassword: data.currentPassword,
      password: data.newPassword,
     
    };

    if (data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: NEW_PASSWORD_CURRENT_PASSWORD_CHECKING,
      });
      setIsLoading(false);
      return;
    }

    request({ url: CHANGE_PASSWORD, method: "put", data: postData })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setTimeout(() => {
            localStorage.removeItem("token");

            dispatch(clearAuthToken());
            navigate("/");
          }, 2000);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <CustomHeading title="Change Password" />
        <CustomToastContainer />
        {/* {isLoading && <CustomLoading />} */}

        <div className="md:pl-4 sm:text-left md:text-right sm:w-full md:w-full text-lg font-sans mb-2 space-x-2">
          <CustomButton
            fieldType="button"
            buttonName="Back"
            click={handleClick}
            backgroundColor="#ED4337"
          />
        </div>
      </div>
      <Card>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <section className="grid md:grid-cols-2 xl:grid-cols-1 gap-5">
              <Controller
                name="currentPassword"
                control={control}
                rules={{ required: CURRENT_PASSWORD_REQUIRED_MESSAGE }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type={showPassword ? "text" : "password"}
                    variant="standard"
                    label="Current Password"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="newPassword"
                control={control}
                rules={{
                  required: NEW_PASSWORD_REQUIRED_MESSAGE,
                  validate: passwordValidation,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type={newPassword ? "text" : "password"}
                    variant="standard"
                    label="New Password"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setNewPassword(!newPassword)}
                            edge="end"
                          >
                            {newPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>

                          <Tooltip
                            className="ml-2 cursor-pointer"
                            title="Your Password must contain atleast 8 to 128 characters 
                Include atleast one uppercase letter, one lowercase letter, one digit, and one special symbol"
                          >
                            {" "}
                            <InfoOutlined />
                          </Tooltip>
                          {/* <IconButton onMouseEnter={handleInfoClick}  edge="end">
                                   <Info />
                            </IconButton> */}

                          <Popover
                            open={Boolean(infoPopover)}
                            anchorEl={infoPopover}
                            onClose={handleInfoClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <Typography style={{ padding: "10px" }}>
                              <ul
                                style={{
                                  paddingLeft: "20px",
                                  margin: "0",
                                  listStyleType: "circle",
                                }}
                              >
                                <li>
                                  Your Password must contain at least 8 to 128
                                  characters
                                </li>
                                <li>
                                  Include at least one uppercase letter, one
                                  lowercase letter, one digit, and one special
                                  symbol
                                </li>
                              </ul>
                            </Typography>
                          </Popover>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: CONFIRM_PASSWORD_REQUIRED_MESSAGE,
                  validate: passwordValidation,
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    type={confirmPassword ? "text" : "password"}
                    variant="standard"
                    label="Confirm Password"
                    error={!!fieldState?.error}
                    helperText={fieldState?.error?.message}
                    onPaste={(e) => e.preventDefault()}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => setConfirmPassword(!confirmPassword)}
                            edge="end"
                          >
                            {confirmPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </section>

            <div className="text-center mt-10">
              <CustomButton fieldType="submit" buttonName="Update" backgroundColor="#62710F"/>
              {isLoading && <CustomLoading />}
            </div>
          </CardContent>
        </form>
      </Card>
    </div>
  );
}

export default ChangePassword;
