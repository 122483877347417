export const PHONE_NUMBER_REQUIRED_MESSAGE = "Phone number is required";

export const EMAIL_REQUIRED_MESSAGE = "Email is required";

export const EMAIL_INVALID_MESSAGE = "Please enter a valid email address";

export const PASSWORD_REQUIRED_MESSAGE = "Password is required";

export const FIRST_NAME_REQUIRED_MESSAGE ="first name is required";

export const NAME_REQUIRED_MESSAGE ="Name is required";

export const MAX_LENGTH_SIXTY_CHARS = "Please provide maximum of 60 characters";

export const MAX_LENGTH_TEN = "Please provide maximum of 10 digits";

export const MIN_LENGTH_TEN = "Please provide minimum of 10 digits";

export const PHONE_NUMBER_INVALID_MESSAGE = "Please provide valid phone number(only digits)"

export const ADDRESS_REQUIRED_MESSAGE = "Please provide Address";

export const MAX_LENGTH_TWO_FIFTY = "Please provide maximum of 250 characters";

export const BRAND_NAME_REQUIRED_MESSAGE = "Brand name is required";

export const MAX_LENGTH_FIVE_HUNDRED_MESSAGE = "Please provide maximum of 500"

export const CATEGORY_ID_REQUIRED_MESSAGE = "Category Id is required";

export const CATEGORY_REQUIRED_MESSAGE = "Category is required";

export const CURRENT_AND_NEW_PASSWORD_CHECKING = "Current password and new password must not be the same";

export const NEW_PASSWORD_CURRENT_PASSWORD_CHECKING ="Confirm password and new password must be the same.";

export const CURRENT_PASSWORD_REQUIRED_MESSAGE = "Current Password is required";

export const NEW_PASSWORD_REQUIRED_MESSAGE = "New Password is required";

export const CONFIRM_PASSWORD_REQUIRED_MESSAGE = "Confirm Password is required";

export const PRODUCT_NAME_REQUIRED_MESSAGE = "Product name is required";

export const PRODUCT_ID_REQUIRED_MESSAGE = "Product Id is required";

export const QUANTITY_REQUIRED_MESSAGE = "Quantity is required";

export const QUANTITY_INVALID_MESSAGE = "Please provide valid Quantity (Only Digits)"

export const MAX_LENGTH_FIVE_DIGITS = "Please provide maximum of 5 digits only";

export const PRICE_REQUIRED_MESSAGE = "Price is required";

export const PRICE_INVALID_MESSAGE = "Please provide valid Price(Only Digits)";

export const VARIANT_NAME_REQUIRED_MESSAGE = "Variant name is required";

export const OTP_REQUIRED_MESSAGE = "OTP is required";

export const OTP_INVALID_MESSAGE ="Please provide valid OTP (Only Digits)";

export const ORDER_ID_REQUIRED_MESSAGE = "Order Id is required";

