export const BASE_URL = "https://api.buytown.in/buytown/api/v1";

export const BASE_URL_IMAGE = "https://api.buytown.in/buytown/api/v1/file";

// export const BASE_URL = "http://localhost:5000/api/v1";

// export const BASE_URL_IMAGE = "http://localhost:5000/api/v1/file";

// export const BASE_URL = "http://94.237.78.225:8080/buytown/api/v1";

// export const BASE_URL_IMAGE = "http://94.237.78.225:8080/buytown/api/v1/file";

// export const BASE_URL = "http://10.64.26.139:5000/api/v1";

// export const BASE_URL_IMAGE = "http://10.64.26.139:5000/api/v1/file";

export const ADMIN_LOGIN = "/auth/login";

export const BRANDS = "/brand";

export const USER = "/user";

export const STAFF = "/staff";

export const CATEGORY = "/category";

export const PRODUCT = "/product";

export const PROFILE = "/profile";

export const PROFILE_ADMIN = "/profile/admin";

export const CHANGE_PASSWORD = "/profile/changepassword";

export const VARIANT = "/variant";

export const ORDER = "/order";

export const DELIVERY_PARTNER_DETAILS = "/staff/partner";

export const ORDER_UPDATE = "/order/assign";

export const OTP_UPDATE = "/order/verifyotp";

export const FORGOT_PASSWORD = "/forgetpassword";

export const FORGOT_PASSWORD_OTP = "/forgetpassword/verifyotp";

export const FORGOT_CHANGE_PASSWORD = "/forgetpassword/changepassword";

export const RECENT_ORDER_DETAILS = "/dashboard";

export const DASHBOARD_DETAILS = "/dashboard";

export const DELIVERY_PARTNER_ORDER_DETAILS = "/order/partner";

export const NOTIFICATION = "/notification";
export const NOTIFICATION_READ_ALL = "/notification/read/all";
