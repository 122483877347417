import React, { useEffect, useMemo, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  DeleteOutline,
  EditNoteOutlined,
  EditOutlined,
  FileDownloadOutlined,
} from "@mui/icons-material";
import { download, generateCsv, mkConfig } from "export-to-csv";
import CustomHeading from "../../components/CustomHeading";
import {
  BASE_URL,
  BASE_URL_IMAGE,
  BRANDS,
  CATEGORY,
  PRODUCT,
} from "../../utills/ApplicationRouting";
import { clearAuthToken } from "../../redux/BuyTown";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router";
import CustomModel from "../../components/CustomModel";
import { useForm } from "react-hook-form";
import CustomButton from "../../components/CustomButton";
import { request } from "../../services/AxiosConfig";
import CustomToastContainer from "../../components/CustomToastContainer";
import CustomLoading from "../../components/CustomLoading";
import CustomInputField from "../../components/CustomInputField";
import {
  MAX_LENGTH_FIVE_DIGITS,
  MAX_LENGTH_TWO_FIFTY,
  PRICE_INVALID_MESSAGE,
  PRICE_REQUIRED_MESSAGE,
  PRODUCT_ID_REQUIRED_MESSAGE,
  PRODUCT_NAME_REQUIRED_MESSAGE,
  QUANTITY_INVALID_MESSAGE,
  QUANTITY_REQUIRED_MESSAGE,
} from "../../utills/ApplicationConstants";
import { FileUploader } from "react-drag-drop-files";
import {
  FIVE_DIGITS_TWO_DECIMAL,
  ONLY_DIGITS,
} from "../../utills/ApplicationRegex";

function ProductList() {
  const { token } = useSelector((state) => state.buytown);
  const fileTypes = ["JPG", "PNG"];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    control,
    reset,
    setError,
    formState: { errors },
  } = useForm();

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 5,
  });

  // get list start code
  const [productDetails, setProductDetails] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const getProductDetails = async () => {
    try {
      setIsLoading(true);
      const storedToken = token;

      console.log(storedToken);

      const response = await axios.get(`${BASE_URL}${PRODUCT}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;

        console.log(data);
        setProductDetails(data);

        setIsLoading(false);
      }  else {
        setIsLoading(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      // Handle error
      setIsLoading(false);
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  // code end

  // delete code start
  const [productId, setProductId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const handleDeleteClickOpen = (id) => {
    setProductId(id);
    console.log(id);
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const onSubmitDelete = async () => {
    console.log(token);
    request({
      url: `${PRODUCT}/${productId}`,
      method: "Delete",
    })
      .then((res) => {
        console.log(res);
        if (res.status == 200) {
          getProductDetails();
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const okCancelButtonActions = (
    <>
      <CustomButton
        fieldType="button"
        buttonName="No"
        backgroundColor="#ED4337"
        click={handleDeleteClose}
      />
      <CustomButton
        fieldType="submit"
        buttonName="Yes"
        backgroundColor="#62710F"
        click={handleDeleteClose}
      />
    </>
  );

  const deleteModel = (
    <form
      onSubmit={handleSubmit(onSubmitDelete)}
    // className="space-y-4 md:space-y-6 "
    >
      <DialogContent dividers>
        <Typography> Do you want to delete this Product ?</Typography>
      </DialogContent>
      <DialogActions>{okCancelButtonActions}</DialogActions>
    </form>
  );

  // delete code end

  // Add code start
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [homeId, setHomeId] = useState("");
  const [previewUrl, setPreviewUrl] = useState(null);
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [inStock, setInStock] = useState(false);
  const getCategory = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${CATEGORY}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const convertedData = data.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        setCategoryData(convertedData);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getBrand = async () => {
    try {
      const storedToken = token;

      const response = await axios.get(`${BASE_URL}${BRANDS}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      console.log(response.data);

      if (response.status === 200) {
        const data = response.data;
        const convertedBrandData = data.map((item) => ({
          label: item.name,
          value: item.id,
        }));

        setBrandData(convertedBrandData);
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getBrand();
  }, []);

  const handleAddClickOpen = () => {
    setOpen(true);
  };

  const handleFileChange = (file) => {
    setFile(file);
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Clear preview for non-image files
      setPreviewUrl(null);
    }
  };
  const handleAddClose = (status) => {
    if (status == 200) {
      setOpen(false);
      setPreviewUrl(null);
      setFile("");
      setCategory(null);
      setBrand(null);
    }
  };
  const handleIconClose = () => {
    setOpen(false);
    setPreviewUrl(null);
    setFile("");
  };

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.productName);
    formData.append("productId", data.productId);
    formData.append("file", file);
    formData.append("categoryId", category);
    formData.append("brandId", brand);
    formData.append("quantity", data.quantity);
    formData.append("price", data.price);
    formData.append("description", data.description);
    formData.append("outOfStock", inStock);
    console.log("formData:", formData);
    setIsLoading(true);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    await request({
      url: PRODUCT,
      method: "post",
      data: formData,
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          handleAddClose(res.status);
          setIsLoading(false);
          getProductDetails();
          setCategory(null);
          getCategory();
        }  else {
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error.response);
        setIsLoading(false);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const addAction = (
    <CustomButton
      fieldType="submit"
      buttonName="Add"
      click={handleAddClose}
      backgroundColor="#62710F"
    />
  );

  const addModel = (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 md:space-y-6 ">
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            requiredErrorMessage={PRODUCT_NAME_REQUIRED_MESSAGE}
            maxLength={250}
            maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
            fieldName="productName"
            fieldId="productName"
            fieldType="text"
            fieldLabel="Product Name"
            placeholder="Enter your Product  Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <CustomInputField
            requiredErrorMessage={PRODUCT_ID_REQUIRED_MESSAGE}
            maxLength={250}
            maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
            fieldName="productId"
            fieldId="productId"
            fieldType="text"
            fieldLabel="Product Id"
            placeholder="Enter your Product Id"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />

          <br />
          <br />
          <FormLabel>
            Attach your File <span style={{ color: "red" }}>*</span>
            <FileUploader
              class="py-5"
              handleChange={handleFileChange}
              name="file"
              types={fileTypes}
              required
            />
            {file ? <p>Selected File: {file.name}</p> : <p>No file selected</p>}
            {previewUrl && (
              <img
                src={previewUrl}
                alt="Preview"
                style={{ maxWidth: "100%" }}
              />
            )}
          </FormLabel>

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={categoryData}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label={
                  <span>
                    Select Category <span style={{ color: "red" }}>*</span>
                  </span>
                }
              />
            )}
            onChange={(event, value) => {
              console.log(event);
              console.log(value);
              if (value !== null) {
                setCategory(value.value);
              }
            }}
          />

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={brandData}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label={
                  <span>
                    Select Brand <span style={{ color: "red" }}>*</span>
                  </span>
                }
              />
            )}
            onChange={(event, value) => {
              console.log(event);
              console.log(value);
              if (value !== null) {
                setBrand(value.value);
              }
            }}
          />
          <CustomInputField

            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={QUANTITY_INVALID_MESSAGE}
            maxLength={5}
            maxLengthErrorMessage={MAX_LENGTH_FIVE_DIGITS}
            fieldName="quantity"
            fieldId="quantity"
            fieldType="text"
            fieldLabel="Quantity"
            placeholder="Enter Quantity"
            fieldControl={control}
            fieldError={errors}

          />
          <CustomInputField
            requiredErrorMessage={PRICE_REQUIRED_MESSAGE}
            fieldPattern={FIVE_DIGITS_TWO_DECIMAL}
            patternErrorMessage={PRICE_INVALID_MESSAGE}
            fieldName="price"
            fieldId="price"
            fieldType="text"
            fieldLabel="Price"
            placeholder="Enter Price"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
          />
          <CustomInputField
            maxLength={250}
            maxLengthErrorMessage={MAX_LENGTH_TWO_FIFTY}
            fieldName="description"
            fieldId="description"
            fieldType="text"
            fieldLabel="Description"
            placeholder="Enter Description"
            fieldControl={control}
            fieldError={errors}

          />

          <FormControlLabel
            control={
              <Switch
                checked={inStock}
                onChange={(event) => setInStock(event.target.checked)}
              />
            }
            label="Out of Stock ? : "
            labelPlacement="start"
          ></FormControlLabel>
        </Typography>
      </DialogContent>
      <DialogActions>{addAction}</DialogActions>
    </form>
  );

  // Add code end

  // Edit code start

  const [editBrandId, setEditBrandId] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [productNameData, setProductNameData] = useState("");
  const [productIdData, setProductIdData] = useState("");
  const [quantityData, setQuantityData] = useState("");
  const [priceData, setPriceData] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  const [InStockData, setInStockData] = useState(false);
  const [updateRes, setUpdateRes] = useState([]);
  const [fileData, setFileData] = useState(null);
  const selectedRow = updateRes;
  const [editPreviewUrl, setEditPreviewUrl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleNameChange = (event) => {
    setProductNameData(event.target.value);
  };

  const handleProductIdChange = (event) => {
    setProductIdData(event.target.value);
  };
  const handlequantityChange = (event) => {
    setQuantityData(event.target.value);
  };
  const handlePriceChange = (event) => {
    setPriceData(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescriptionData(event.target.value);
  };
  const handleEditFileChange = (file) => {
    setFileData(file);
    setEditPreviewUrl(URL.createObjectURL(file));
  };

  const handleEditClose = (status) => {
    if (status == 200) {
      setSelectedRowId(null);

      setEditOpen(false);
    }
  };
  const handleEditIconClose = () => {
    setEditOpen(false);
    getProductDetails();
    setSelectedRowId(null);
    setEditPreviewUrl(null);
    setFileData("");
  };

  const getProductDetailsById = async (id) => {
    try {
      const storedToken = token;
      const response = await axios.get(`${BASE_URL}${PRODUCT}/${id}`, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
        },
      });
      setIsLoading(true);
      console.log(response.data);
      if (response.status === 200) {
        getCategory();
        const data = response.data;
        console.log(data.image);
        setIsLoading(false);
        setUpdateRes(data.id);
        setProductNameData(data.name);
        setProductIdData(data.productId);
        setQuantityData(data.quantity);
        setPriceData(data.price);
        setDescriptionData(data.description);
        const imageUrl = (data.image);
        // setFileData(data.image);
        setFileData(imageUrl);
        setEditPreviewUrl(imageUrl);
        // setFileData(data.image);
        // setFetchedImageUrl(data.image);
        setCategory(data.categoryId);
        setBrand(data.brandId);
        if (data.outOfStock == true) {
          setInStockData(true);
        }
      } else {
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 403) {
        localStorage.removeItem("token");
        dispatch(clearAuthToken());
        navigate("/");
      }
    }
  };

  const handleEditClickOpen = (selectedRow) => {
    setEditOpen(true);

    setSelectedRowId(selectedRow.original.id);
    getProductDetailsById(selectedRow.original.id);
    console.log("selectedRow:", selectedRow);
    console.log("selectedRow.id:", selectedRow.original.id);
  };

  const onSubmitEdit = (e) => {
    setIsLoading(true);
    if (!productNameData || productNameData.trim() === "") {
      setError("editProductName", {
        type: "manual",
        message: PRODUCT_NAME_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (productNameData.trim().length > 250) {
      setError("editProductName", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY,
      });
      return;
    }
    setIsLoading(false);

    if (!productIdData || productIdData.trim() === "") {
      setError("editProductId", {
        type: "manual",
        message: PRODUCT_ID_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }

    if (productIdData.trim().length > 250) {
      setError("editProductId", {
        type: "manual",
        message: MAX_LENGTH_TWO_FIFTY,
      });
      setIsLoading(false);
      return;
    }

    if (quantityData) {
      if (quantityData.trim().length > 5) {
        setError("editQuantity", {
          type: "manual",
          message: MAX_LENGTH_FIVE_DIGITS,
        });
        return;
      }
    }



    if (!priceData || !priceData.trim() === "") {
      setError("editPrice", {
        type: "manual",
        message: PRICE_REQUIRED_MESSAGE,
      });
      setIsLoading(false);
      return;
    }
    if (descriptionData) {
      if (descriptionData.trim().length > 250) {
        setError("editDescription", {
          type: "manual",
          message: MAX_LENGTH_TWO_FIFTY,
        });
        setIsLoading(false);
        return;
      }
    }

    setIsLoading(false);

    const formData = new FormData();

    formData.append("name", productNameData.trim());
    formData.append("productId", productIdData.trim());
    formData.append("file", fileData);
    formData.append("categoryId", category);
    formData.append("brandId", brand);
    formData.append("quantity", productNameData.trim());
    formData.append("price", priceData.trim());
    formData.append("description", descriptionData.trim());
    formData.append("outOfStock", InStockData);
    const storedToken = token;

    console.log(storedToken);
    const headers = {
      "Content-Type": "multipart/form-data",
    };

    request({
      url: `${BASE_URL}${PRODUCT}/${selectedRow}`,
      data: formData,
      method: "put",
      headers,
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.status == 200) {
          setIsLoading(false);
          handleEditClose(res.status);
          getProductDetails();
        }
      })
      .catch((error) => {
        console.log(error.res);
        setIsLoading(false);
        if (error.response.status === 403) {
          localStorage.removeItem("token");
          dispatch(clearAuthToken());
          navigate("/");
        }
      });
  };

  const editAction = (
    <>
      <CustomButton
        fieldType="submit"
        buttonName="Update "
        click={handleEditClose}
        backgroundColor="#62710F"
      />
    </>
  );

  const getCategoryValue = (category) => {
    console.log("category :" + category);
    console.log(categoryData);
    const value = categoryData.filter((data) => data.value == category);
    console.log(value.length);
    if (value.length > 0) {
      console.log("success");
      return value[0].label;
    } else {
      return null;
    }
  };

  const getBrandValue = (brand) => {
    console.log("brandData :" + brand);
    console.log(brandData);
    const value = brandData.filter((data) => data.value == brand);
    console.log(value.length);
    if (value.length > 0) {
      console.log("success");
      return value[0].label;
    } else {
      return null;
    }
  };
  const editModel = (
    <form onSubmit={handleSubmit(onSubmitEdit)}>
      {isLoading && <CustomLoading />}
      <DialogContent dividers>
        <Typography gutterBottom>
          <CustomInputField
            fieldName="editProductName"
            fieldId="editProductName"
            fieldType="text"
            fieldLabel="Product Name"
            placeholder="Enter your Product Name"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={productNameData}
            onInput={handleNameChange}
          />

          <CustomInputField
            fieldName="editProductId"
            fieldId="editProductId"
            fieldType="text"
            fieldLabel="Product Id"
            placeholder="Enter your Product Id"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={productIdData}
            onInput={handleProductIdChange}
          />
          <br />
          <br />
          <FormLabel>
            Attach your File{" "}
            <FileUploader
              class="py-5"
              handleChange={handleEditFileChange}
              name="file"
              types={fileTypes}
            //   required
            />
            {editPreviewUrl && (
              <div>
                <p>Selected File: {fileData.name}</p>
                <img
                  src={editPreviewUrl}
                  alt="Preview"
                  style={{ maxWidth: "100%", maxHeight: "200px" }}
                />
              </div>
            )}
          </FormLabel>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={categoryData}
            value={getCategoryValue(category)}
            // defaultValue={getCategoryValue(category)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label="Select Category"
              />
            )}
            onChange={(event, value) => {
              if (value !== null) {
                setCategory(value.value);
              }
            }}
          />

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={brandData}
            value={getBrandValue(brand)}
            // defaultValue={getBrandValue(brand)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="standard"
                label="Select Brand"
              />
            )}
            onChange={(event, value) => {
              if (value !== null) {
                setBrand(value.value);
              }
            }}
          />

          <CustomInputField
            fieldPattern={ONLY_DIGITS}
            patternErrorMessage={QUANTITY_INVALID_MESSAGE}
            fieldName="editQuantity"
            fieldId="editQuantity"
            fieldType="text"
            fieldLabel="Quantity"
            placeholder="Enter your Quantity"
            fieldControl={control}
            fieldError={errors}

            value={quantityData}
            onInput={handlequantityChange}
          />
          <CustomInputField
            fieldPattern={FIVE_DIGITS_TWO_DECIMAL}
            patternErrorMessage={PRICE_INVALID_MESSAGE}
            fieldName="editPrice"
            fieldId="editPrice"
            fieldType="text"
            fieldLabel="Price"
            placeholder="Enter Price"
            fieldControl={control}
            fieldError={errors}
            requiredIcon="*"
            value={priceData}
            onInput={handlePriceChange}
          />
          <CustomInputField
            fieldName="editDescription"
            fieldId="editDescription"
            fieldType="text"
            fieldLabel="Description"
            placeholder="Enter Description"
            fieldControl={control}
            fieldError={errors}

            value={descriptionData}
            onInput={handleDescriptionChange}
          />

          <FormControlLabel
            control={
              <Switch
                checked={InStockData}
                onChange={(event) => setInStockData(event.target.checked)}
              />
            }
            label="Out of Stock ? : "
            labelPlacement="start"
          ></FormControlLabel>
        </Typography>
      </DialogContent>

      <DialogActions>{editAction}</DialogActions>
    </form>
  );

  // Edit code end

  const productTable = useMemo(() => [
    {
      accessorKey: "serialNo",
      header: "Serial No",
      size: 20,
      accessorFn: (row, index) => index + 1,
    },
    {
      accessorKey: "image",
      header: "Image",
      size: 20,
      Cell: ({ row }) => (
        <img
          src={`${row.original.image}`}
          alt={row.original.image}
          style={{ width: 150, height: 150 }}
        />
      ),
    },
    {
      accessorKey: "category name",
      header: "Category Name",
      size: 20,
      accessorFn: (row) => (row && row.category ? row.category.name : " "),
    },
    {
      accessorKey: "brand name",
      header: "Brand Name",
      size: 20,
      accessorFn: (row) => (row && row.brand ? row.brand.name : " "),
    },
    {
      accessorKey: "name",
      header: "Product Name",
      size: 20,
      accessorFn: (row) => row && row.name,
    },
    {
      accessorKey: "variant name",
      header: "Variant Name",
      size: 20,
      accessorFn: (row) => {
        if (row && row.variants && row.variants.length > 0) {
          const variantNames = row.variants.map((variant, index) => (
            <div key={index}>{variant.name}</div>
          ));
          return variantNames;
        } else {
          return "";
        }
      },
    },

    {
      accessorKey: "outOfStock",
      header: "Out of Stock",
      size: 20,
      accessorFn: (row) =>
        row && row.outOfStock ? "Out of Stock" : "In-Stock",
    },
    {
      accessorKey: "quantity",
      header: "Quantity",
      size: 20,
      accessorFn: (row) => row && row.quantity,
    },
    {
      accessorKey: "price",
      header: "Price",
      size: 20,
      accessorFn: (row) => row && row.price,
    },
  ]);

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: true,
  });

  const handleExportData = () => {
    const csv = generateCsv(csvConfig)(productDetails);
    download(csvConfig)(csv);
  };

  return (
    <div>
      <CustomToastContainer />
      {isLoading && <CustomLoading />}
      <CustomHeading title="Products" />
      <MaterialReactTable
        columns={productTable}
        data={productDetails}
        positionActionsColumn="last"
        enableColumnPinning={true}
        initialState={{
          grouping: ["category name", "brand name"],
          expanded: true,
          columnPinning: { right: ["mrt-row-actions"] },
        }}
        enableGrouping={true}
        enableRowActions
        renderRowActions={({ row }) => (
          <Box>
            <IconButton onClick={() => handleEditClickOpen(row)}>
              <EditNoteOutlined color="success" />
            </IconButton>
            <IconButton onClick={() => handleDeleteClickOpen(row.original.id)}>
              <DeleteOutline color="error" />
            </IconButton>
          </Box>
        )}
        renderDetailPanel={({ row }) =>
          row.original.variants && row.original.variants.length > 0 ? (
            <Box
              sx={{
                display: "grid",
                margin: "auto",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                width: "100%",
              }}
            >
              {row.original.variants.map((variant, index) => (
                <Box key={index}>
                  <img
                    src={`${BASE_URL_IMAGE}/${variant.image}`}
                    alt={variant.image}
                    style={{ width: 150, height: 150 }}
                  />
                  <Typography>Variant Name: {variant.name}</Typography>
                  <Typography>Description: {variant.description}</Typography>
                  <Typography>Quantity: {variant.quantity}</Typography>
                  <Typography>Price: {variant.price}</Typography>
                </Box>
              ))}
            </Box>
          ) : null
        }
        renderTopToolbarCustomActions={({ table }) => (
          <>
            <Box
              sx={{
                display: "flex",
                gap: "16px",
                padding: "8px",
                flexWrap: "wrap",
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: "#62710F" }}
                onClick={() => handleAddClickOpen()}
              >
                Add
              </Button>
            </Box>
          </>
        )}
      />

      <CustomModel
        title="Product"
        submit={onSubmit}
        content={addModel}
        action={addAction}
        openStatus={open}
        closeStatus={handleAddClose}
        iconCloseStatus={handleIconClose}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomModel
        title="Edit Product"
        submit={onSubmitEdit}
        content={editModel}
        action={editAction}
        openStatus={selectedRowId !== null}
        closeStatus={handleEditClose}
        iconCloseStatus={handleEditIconClose}
        reset={reset}
        isLoading={isLoading}
      />
      <CustomModel
        title="Delete Product"
        submit={onSubmitDelete}
        content={deleteModel}
        action={okCancelButtonActions}
        openStatus={deleteOpen}
        closeStatus={handleDeleteClose}
        iconCloseStatus={handleDeleteClose}
        reset={reset}
        isLoading={isLoading}
        modelStyle={{ "& .MuiDialog-paper": { borderRadius: "10px" } }}
      />
    </div>
  );
}

export default ProductList;
