import React from "react";
import CustomHeader from "./CustomHeader";

function CustomLayout({ children }) {
  return (
    <>
      <CustomHeader />
      <div class="p-4 sm:ml-64">
        <div class="p-4 mt-14">{children}</div>
      </div>
    </>
  );
}

export default CustomLayout;
